import { useEffect } from "react";
import Loading from "../../common/components/Loading";
import UserProfile from "../../common/components/UserProfile";
import { usePopup } from "../../common/contexts/PopupContext";
import useApiData from "../../common/hooks/useApiData";
import useTicket from "../hooks/useTicket";
import TicketHeading from "./TicketHeading";
import ViewMoreWrapper from "../../common/components/ViewMoreWrapper";

const statusOptions = [
  { label: "Pending", value: "PENDING" },
  { label: "In Progress", value: "INPROGRESS" },
  { label: "Hold", value: "HOLD" },
  { label: "Completed", value: "COMPLETED" },
];

const getStatusCls = (status) => {
  switch (status) {
    case "PENDING":
      return "text-yellow-600 border-yellow-600 bg-yellow-50";
    case "INPROGRESS":
      return "text-orange-500 border-orange-500 bg-orange-50";
    case "HOLD":
      return "text-blue-600 border-blue-600 bg-blue-50";
    case "COMPLETED":
      return "text-green-600 border-green-600 bg-green-50";
    default:
      return "white";
  }
};

function TicketTeamAssignees({ ticketId }) {
  const { toast } = usePopup();

  const { getTicketAssignees } = useTicket();

  const { data, mutate, loading } = useApiData({
    onSuccess: (res) => {},
    onError: (error) => {
      toast("Unable to get ticket team assignees", "error");
    },
  });

  useEffect(() => {
    if (ticketId) {
      mutate(getTicketAssignees({}, ticketId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const teamAssignees = data?.data || [];

  return (
    <div className="border mt-4">
      <TicketHeading label="Team Assignee(s)" />

      {loading ? (
        <div className="p-4">
          <Loading />
        </div>
      ) : (
        <div className="my-1 text-sm px-4">
          {teamAssignees.length ? (
            <>
              {teamAssignees.map((team, i) => {
                const { teamDetails, assignees } = team;
                const { name, status, canStatusEdit, ticket_team_id, team_id } =
                  teamDetails || {};

                const selectedStatus = statusOptions.find(
                  (s) => s.value === status
                );

                return (
                  <div
                    key={i}
                    className="flex justify-between items-center space-x-4 text-sm my-3"
                  >
                    <div className="w-1/2">
                      <div className="py-2 font-semibold text-md">{name}</div>

                      {assignees?.length ? null : (
                        <div className="font-semibold text-gray-500 text-sm">
                          No Assignees
                        </div>
                      )}

                      <ViewMoreWrapper
                        data={assignees?.length ? assignees : []}
                        labelCls="!my-2 !text-[13px]"
                        iconSize={20}
                        viewMoreLabel="View more assignee(s)"
                      >
                        {(data) => {
                          const assignees = [];

                          data.forEach((d) => {
                            assignees.push(d.username || d.email);
                          });

                          return (
                            <div className="text-gray-500">
                              {assignees.join(", ")}
                            </div>
                          );
                        }}
                      </ViewMoreWrapper>
                    </div>

                    <div
                      className={`px-3 py-1.5 text-[12px] rounded-full font-semibold border ${getStatusCls(
                        status
                      )}`}
                    >
                      {selectedStatus?.label}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="font-semibold text-gray-500 text-center py-2">
              No Team Assignees
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TicketTeamAssignees;
