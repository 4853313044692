import { Edit } from "lucide-react";
function TicketHeading({ label, onEdit, onManage }) {
  return (
    <div className="justify-between py-2 font-mulisemibold text-skin-base border-b px-4 pt-2 flex items-center space-x-3">
      <div className="font-muli text-skin-base text-base">{label}</div>
      <div className="flex gap-1 items-center">
        {onEdit ? (
          <span className="text-sm pl-3 pt-[7px] cursor-pointer" >
            <Edit
              size={15}
              color="#005f73"
              onClick={onEdit}
            />
          </span>
        ) : null}

        {onManage ? (
          <button className="text-white rounded-md px-5 py-[5px] font-muli bg-primary text-sm hover:opacity-90" onClick={() => onManage.onClick()}>
            {onManage.label}
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default TicketHeading;
