import { useNavigate } from "react-router-dom";
import SignUpForm from "../components/SignUpForm";
import { checkAuth } from "../../common/helpers/commonHelpers";
import { useEffect } from "react";
import useCustomNavigate from "../../common/hooks/useCustomNavigate";

function SignUpContainer() {
  const navigate = useCustomNavigate();

  useEffect(() => {
    if (checkAuth()) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SignUpForm />
    </div>
  );
}

export default SignUpContainer;
