import "./App.css";
import LoginContainer from "./auth/container/LoginContainer";
import SignUpContainer from "./auth/container/SignUpContainer";
import Header from "./common/components/Header";
import HomeContainer from "./home/container/HomeContainer";
import TicketFormContainer from "./ticket/container/TicketFormContainer";
import TicketsContainer from "./ticket/container/TicketsContainer";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Route,
  createRoutesFromElements,
  useParams,
  useNavigate,
} from "react-router-dom";
import { basePath, checkAuth } from "./common/helpers/commonHelpers";
import { PopupProvider } from "./common/contexts/PopupContext";
import { useApp } from "./common/contexts/AppContext";
import AuthProvider, { useAuth } from "./common/contexts/AuthContext";
import { useEffect } from "react";
import { setLocalStorage } from "./common/helpers/browserStorage";

const publicRoutes = [
  {
    // index: true,
    path: "/",
    element: <HomeContainer />,
  },
  {
    path: "/login",
    element: <LoginContainer />,
  },
  {
    path: "/sign-up",
    element: <SignUpContainer />,
  },
];

const privateRoutes = [
  {
    path: "/tickets",
    element: <TicketsContainer />,
  },
  {
    path: "/create-ticket",
    element: <TicketFormContainer />,
  },
];

const notFoundRoute = {
  path: "/*",
  element: <HandleRedirect />,
};

function getRoutes(accessToken) {
  const routes = accessToken ? privateRoutes : [];

  return [...publicRoutes, ...routes, notFoundRoute];
}

function getRouter(accessToken) {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Header />}>
        {getRoutes(accessToken).map((route, index) => {
          return (
            <Route key={index} element={route.element} path={route.path} />
          );
        })}
      </Route>
    ),
    { basename: "/" }
  );

  return router;
}

function App() {
  return (
    <div className="overflow-x-hidden">
      {/* {baseName ? "" : "Invalid url"} */}

      <PopupProvider>
        <AuthProvider>
          <ProvideRouter />
          {/* {baseName ? <ProvideRouter baseName={baseName} /> : null} */}
        </AuthProvider>
      </PopupProvider>
    </div>
  );
}

export default App;

function ProvideRouter() {
  const { accessToken } = useAuth();

  return <RouterProvider router={getRouter(accessToken)} />;
}

function HandleRedirect() {
  const { id } = useParams();
  const navigate = useNavigate();

  const routeTo = checkAuth() ? `/` : `/login`;

  useEffect(() => {
    const isPublicRoute =
      window.location.href.includes("login") ||
      window.location.href.includes("signin");

    if (!checkAuth() && !isPublicRoute) {
      const { pathname, search } = window.location;

      const requestedPath = pathname + search;

      setLocalStorage("requested-path", requestedPath);
    }

    navigate(routeTo);
  }, []);

  return;
}
