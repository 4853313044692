import { useEffect, useRef, useState } from "react";
import Loading from "../../common/components/Loading";
import UserProfile from "../../common/components/UserProfile";
import { usePopup } from "../../common/contexts/PopupContext";
import useApiData from "../../common/hooks/useApiData";
import useTicket from "../hooks/useTicket";
import TicketHeading from "./TicketHeading";
import ViewMoreWrapper from "../../common/components/ViewMoreWrapper";
import { Trash2Icon } from "lucide-react";
import Modal from "../../common/components/Modal";
import CreatableSelect from "react-select/creatable";
import {
  handleFileUpload,
  isQuillHavingText,
  isValidEmail,
  priorities,
  udpateState,
  validateAdditionalFields,
  validateRequiredInput,
} from "../../common/helpers/commonHelpers";
import PrimaryButton from "../../common/components/PrimaryButton";
import CcEmails from "./CcEmails";
function getDefaultForm() {
  return {
    cc: [],
  };
}

function TicketCcs({ ticketId }) {
  const [form, setForm] = useState(getDefaultForm());
  const [addCcModal, setAddCcModal] = useState(false);
  const [ccError, setCCError] = useState("");
  const [submitBtnClickedOnce, setSubmitBtnClickedOnce] = useState(false);

  const { toast } = usePopup();

  const { getTicketClients, removeClientFromTicket, addClientToTicket } =
    useTicket();

  const { data, mutate, loading } = useApiData({
    onSuccess: (res) => { },
    onError: (error) => {
      toast("Unable to get ticket ccs", "error");
    },
  });

  const { mutate: removeCcMutate, loading: isRemoveLoading } = useApiData({
    onSuccess: (res) => {
      mutate(getTicketClients(ticketId));
    },
    onError: (error) => {
      toast("Unable to remove ticket cc", "error");
    },
  });

  const { mutate: addCcMutate, loading: isAddLoading } = useApiData({
    onSuccess: (res) => {
      setCCError("");
      setForm(getDefaultForm());
      setAddCcModal(false);
      mutate(getTicketClients(ticketId));
      toast("New emails added to ticket cc", "success");
    },
    onError: (error) => {
      toast("Unable to add ticket cc", "error");
    },
  });

  useEffect(() => {
    if (ticketId) {
      mutate(getTicketClients(ticketId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInputChange(e, setForm) {
    const { value, name } = e.target;

    const payload = { [name]: value };

    udpateState(setForm, payload);
  }

  const clientDetails = data?.data || [];

  return (
    <div className="border mt-4">
      <TicketHeading
        label="Cc(s)"
        onManage={{
          label: "Add Cc",
          onClick: () => {
            setAddCcModal(true);
          },
        }}
      />

      {loading ? (
        <div className="p-4">
          <Loading />
        </div>
      ) : (
        <div className="my-1 text-sm px-4">
          {clientDetails.length ? (
            <>
              {clientDetails.map((client, i) => {
                return (
                  <ClientCard
                    client={client}
                    onRemove={(uuid) => {
                      removeCcMutate(removeClientFromTicket(ticketId, uuid));
                    }}
                    isRemoveTicketClientLoading={isRemoveLoading}
                  />
                );
              })}
            </>
          ) : (
            <div className="font-medium text-skin-tertiary text-center py-2">
              No Ccs Found
            </div>
          )}
        </div>
      )}
      <Modal
        isOpen={addCcModal}
        onClose={() => setAddCcModal(false)}
        contentCls="max-h-[80vh] w-[600px]"
      >
        <div>
          <div className="font-mulisemibold sm:text-2xl text-lg text-center text-skin-base">
            Add Ccs
          </div>
        </div>
        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            addCcMutate(addClientToTicket(ticketId, form));
          }}
        >
          <div className={`my-1 font-mulisemibold text-sm text-skin-base`}>CC</div>
          <CcEmails
            handleInputChange={(payload) => {
              handleInputChange(payload, setForm);
            }}
            form={form}
            setCCError={setCCError}
          />
          {/* <CreatableSelect
            styles={{
              control: (provided, state) => ({
                ...provided,
                border: 0,
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#005f73"
                },
                border: state.isFocused ? "1px solid #005f73" : "1px solid #DADCE0", // Change border color when focused
                borderRadius: "4px",
                outline: state.isFocused ? "none" : "",
                fontFamily: "Muli light",
                fontSize: "text-skin-base",
                backgroundColor: "white",
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                background: isFocused
                  ? '#F4FDFF'
                  : isSelected
                    ? '#F4FDFF'
                    : "#ffffff",
                zIndex: 1
              }),
              multiValue: (styles, { data }) => {
                return {
                  ...styles,
                  backgroundColor: "#d6f9ff",
                };
              },
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: "#182444",
                fontFamily: "Muli light"
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: "#9f9f9f",
                ':hover': {
                  backgroundColor: "#d6f9ff",
                  color: '#f98c76',
                },
              }),
              // other styles as needed
            }}
            isMulti
            value={
              form?.cc?.length
                ? form?.cc.map((c) => ({ label: c, value: c }))
                : null
            }
            options={[]}
            onChange={(val, actionData) => {
              const { action, option } = actionData;

              if (action === "create-option" && !isValidEmail(option?.value)) {
                setCCError("Invalid Email");
              } else {
                setCCError(false);
              }

              const validEmails = val?.filter((email) =>
                isValidEmail(email.value)
              );

              const payload = {
                target: {
                  name: "cc",
                  value: validEmails?.length
                    ? validEmails.map((c) => c.value)
                    : [],
                },
              };

              handleInputChange(payload, setForm);
            }}
            placeholder="name@example.com"
            formatCreateLabel={(data) => {
              console.log("data", data);
              return `Add ${data}`;
            }}
          /> */}

          {ccError && (
            <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{ccError}</div>
          )}

          <PrimaryButton
            type="submit"
            btnCls="!w-fit px-6 font-mulisemibold text-sm md:py-2 py-[6px] mt-4"
            loading={isAddLoading}
          >
            Add Ccs
          </PrimaryButton>
        </form>
      </Modal>
    </div>
  );
}

export default TicketCcs;

const ClientCard = ({ client, onRemove, isRemoveTicketClientLoading }) => {
  const [open, setOpen] = useState(false);
  const btnRef = useRef();
  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);
  return (
    <div
      className="hover:bg-[#F4FDFF] w-full rounded-b-md lg:px-3 px-0 py-2 text-gray-700 text-md bg-white flex items-center justify-between mt-2"
      rel="noreferrer"
    >
      {/* {JSON.stringify(userDetails)} */}
      <div className="sm:flex items-center gap-2">
        <div className="text-lg font-mulisemibold bg-primary text-white flex items-center justify-center rounded-full px-2 sm:w-[40px] w-[30px] sm:h-[40px] h-[30px] pb-[2px]">
          {client && client.client_name
            ? Array.from(client.client_name)[0]
            : "C"}
        </div>
        <div className="detailsUser">
          <div className="userName text-base font-mulisemibold text-skin-base">
            {client.client_name ? client.client_name : "No Name"}
          </div>
          <div className="others text-sm font-mulilight text-skin-base">
            {client.client_email ? client.client_email : "No Email"}
          </div>
          {client.is_primary === 1 && (
            <div className="bg-[#e5eff1] py-1 w-[80px] rounded-sm text-primary px-3 text-sm">
              Primary
            </div>
          )}
        </div>
      </div>

      <div className="md:flex items-center">
        {client.is_primary !== 1 && (
          <div className="dropdown flex relative" ref={btnRef}>
            <div
              className="dropdown-toggle"
              id="dropdown-toggle-873-eVCGyHdcfE"
            >
              <button
                className="button bg-gray-100 hover:bg-transparent hover:border-red-300 border rounded-md hover:opacity-90 active:bg-secondary text-skin-textred radius-circle h-7 w-7 inline-flex items-center justify-center text-xl"
                // onClick={(e) => {
                //   e.preventDefault();
                //   setOpen(!open);
                // }}
                onClick={(e) => {
                  e.preventDefault();
                  onRemove(client.uuid);
                }}
              >
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                </svg> */}
                <Trash2Icon className="w-[15px] h-[15px]" />
              </button>
            </div>
            {/* <ul
              id="base-menu-124-eIThs96oYp"
              className={`hover:bg-red-300 dropdown-menu bottom-end ${open ? "block" : "hidden"
                } right-[0%] min-w-[100px] top-[20px]`}
              style={{
                opacity: 1,
                visibility: "visible",
                transform: "rotateX(0deg)",
              }}
            >
              <li
                className="px-2 py-1 flex cursor-pointer rounded-sm items-center menu-item menu-item-dark menu-item-hoverable text-red-500 hover:text-white"
                onClick={(e) => {
                  e.preventDefault();
                  onRemove(client.uuid);
                }}
              >
                {isRemoveTicketClientLoading && <Loading />}
                <span className="text-base">
                  <Trash2Icon className="w-[15px] h-[15px]" />
                </span>
                <span className="ml-2 rtl:mr-2 text-skin-base">Remove</span>
              </li>
            </ul> */}
          </div>
        )}
      </div>
    </div>
  );
};
