import { useMemo, useState } from "react";
import UserProfile from "../common/components/UserProfile";
import useDropDown from "../common/hooks/useDropDown";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../common/contexts/AuthContext";
import useCustomNavigate from "../common/hooks/useCustomNavigate";

function Profile() {
  const [open, setOpen] = useState(false);
  const { elementRef } = useDropDown({ setOpen });
  const { setUser, setAccessToken, user } = useAuth();

  const navigate = useCustomNavigate();

  const { email, username, image } = user;

  const options = useMemo(() => {
    return [
      {
        label: username || email,
        onClick: () => { },
      },
      // {
      //   label: "My Profile",
      //   onClick: () => {},
      // },
      {
        label: "Sign out",
        onClick: () => {
          navigate("/login");
          setUser({});
          setAccessToken("");
          localStorage.clear();
        },
      },
    ];
  }, [username, email, navigate, setUser, setAccessToken]);

  return (
    <div className="relative" ref={elementRef}>
      {/* <button onClick={() => setOpen(!open)} className="">
        fd
        <UserProfile src={image} name={username || email} className="w-12" />
      </button> */}

      <button onClick={() => setOpen(!open)} className="">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#005f73" className="w-7 h-7 font-mulisemibold">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>
      </button>

      {
        open ? (
          <div className="fade-in absolute top-12 -left-20 right-0 bg-white border shadow-mainshadow rounded-md w-32">
            {options.map((option, i) => {
              const { label, onClick } = option;
              console.log("label-------",label);
              console.log("i--------",i);
              return (
                <button
                  onClick={onClick}
                  key={i}
                  className={`block text-base text-skin-base font-mulilight w-full py-2 px-4 border-b hover:bg-[#F4FDFF] truncate ${i === 0 ? "text-base text-skin-base font-mulibold" : "text-base text-skin-base font-mulilight"
                    }`}
                >
                  {label}
                </button>
              );
            })}
          </div>
        ) : null
      }
    </div >
  );
}

export default Profile;
