import { useMemo } from "react";
import apiInstance from "../../common/helpers/apiInstance";
import { useAuth } from "../../common/contexts/AuthContext";
import { useApp } from "../../common/contexts/AppContext";

function useTicket() {
  const { user = {} } = useAuth();
  const { portal } = useApp();

  const { community_id } = portal || {};

  const basePath = useMemo(() => {
    return "/" + community_id;
  }, [community_id]);

  return {
    getCommunityFields: (payload) => {
      return () => apiInstance.post(basePath + "/getCommunityFields", payload);
    },
    getTickets: (payload, page) => {
      return () =>
        apiInstance.post(basePath + `/getTickets?page=${page}`, payload);
    },
    getTicket: (payload, ticketId) => {
      return () => apiInstance.post(basePath + `/getTicket/${ticketId}`);
    },
    getTicketAssignees: (payload, ticketId) => {
      return () =>
        apiInstance.get(basePath + `/getTicketAssignees/${ticketId}`);
    },
    createTicket: (payload) => {
      return () => apiInstance.post(basePath + "/createTicket", payload);
    },
    updateTicket: (payload, ticketId) => {
      return () =>
        apiInstance.post(basePath + `/updateTicket/${ticketId}`, payload);
    },
    addComment: (payload, ticketId) => {
      return () =>
        apiInstance.post(basePath + `/addClientComment/${ticketId}`, payload);
    },
    updateComment: (payload, ticketId) => {
      return () =>
        apiInstance.post(
          basePath + `/updateClientComment/${ticketId}`,
          payload
        );
    },
    updateCustomFields: (payload, ticketId) => {
      return () =>
        apiInstance.post(basePath + `/updateCustomFields/${ticketId}`, payload);
    },
    getTicketClients: (ticketId) => {
      return () => apiInstance.get(basePath + `/${ticketId}/getTicketClients`);
    },
    addClientToTicket: (ticketId, payload) => {
      return () =>
        apiInstance.post(basePath + `/${ticketId}/addClientToTicket`, payload);
    },
    removeClientFromTicket: (ticketId, uuid) => {
      return () =>
        apiInstance.delete(
          basePath + `/${ticketId}/removeClientFromTicket/${uuid}`
        );
    },
    getSimilarEmails: (value) => {
      return () =>
        apiInstance.get("/auth/getClientMails", {
          params: {
            email: value,
          },
        });
    },
  };
}

export default useTicket;
