import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { checkAuth, getOrigin } from "../helpers/commonHelpers";
import Profile from "../../profile/Profile";
import useCustomNavigate from "../hooks/useCustomNavigate";
import apiInstance from "../helpers/apiInstance";
import useApiData from "../hooks/useApiData";
import { useEffect, useState, Fragment } from "react";
import Loading from "./Loading";
import PortalDisabled from "./PortalDisabled";
import { useAuth } from "../contexts/AuthContext";
import { useApp } from "../contexts/AppContext";
import { X } from "lucide-react";
import { } from "lucide-react";
// import commerceLogo from "../../../public/commerce-logo.png"


function checkPortal(payload) {
  return () => apiInstance.post("/auth/checkPortal", payload);
}

function getPublicNavs(navigate) {
  return [
    {
      label: "Home",
      onClick: () => navigate(`/`),
      path: `/`,
      isPageActive: (id, pathname) => {
        if (pathname === `/${id}` || pathname === `/${id}/`) {
          return true;
        }

        return false;
      },
    },
  ];
}

function getPrivateNavs(navigate) {
  return [
    {
      label: "Tickets",
      onClick: () => navigate(`/tickets`),
      path: `/tickets`,
    },
  ];
}

const commonNavCls =
  "px-4 py-2 rounded-md transition-all duration-150 ease-in-out mx-1";

function Header() {
  const [portalError, setPortalError] = useState("");
  const [closeInfo, setCloseInfo] = useState();
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  const { setBaseName, portal, setPortal } = useApp();

  const accessToken = checkAuth();

  const navigate = useCustomNavigate();

  const { pathname } = useLocation();

  const id = "/";

  const { data, mutate, loading } = useApiData({
    onSuccess: (res) => {
      const { status, message, portal } = res?.data;

      setBaseName(id);
      setPortal(portal);

      setCloseInfo(portal?.info ? true : false);

      if (status === "Error") {
        setPortalError(message);
      }
    },
    onError: (err) => {
      const { message } = err?.response?.data;
      setPortalError(message ? message : "Portal not working.");
    },
  });

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); // Assuming lg breakpoint is 1024px
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);


  const submitTicketPath = `/create-ticket`;

  const loginPath = `/login`;

  const signUpPath = `/sign-up`;

  const headerNavs = accessToken
    ? [...getPublicNavs(navigate), ...getPrivateNavs(navigate)]
    : getPublicNavs(navigate);

  useEffect(() => {
    const url = getOrigin() + "/support/" + id;
    mutate(checkPortal());
  }, []);

  if (!id) {
    return "Invalid Url";
  }

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="text-gray-500 font-mulilight text-xl my-2">
          Please wait...
        </div>
        <Loading />
      </div>
    );
  }

  if (portalError) {
    return <PortalDisabled error={portalError} />;
  }

  return (
    <>
      <div className="">
        <div className={`fixed bg-[#F4FDFF] z-[2] w-full top-0 ${closeInfo ? "h-[160px]" : "h-[80px]"}`}></div>
        <div className="fixed top-3 container left-[50%] translate-x-[-50%] z-50">
          <div
            className={`shadow-simpleshadow rounded-[10px] bg-white`}
          >
            {closeInfo && (
              <div className="ribbon flex gap-3 items-center justify-center w-full h-[4rem] bg-primary text-white text-center">
                <span>{portal.info}</span>
                <button
                  className="rounded p-1 "
                  onClick={() => setCloseInfo(false)}
                >
                  <X className="w-[20px]" />
                </button>
              </div>
            )}
            <div className="py-4 px-8 flex items-center justify-between mx-auto">
              <div>
                <div className="font-semibold cursor-pointer" onClick={() => navigate("/")}>
                  <img src={`../../../commerce-logo.png`} alt="logo"
                    className="md:w-[200px] w-[150px]"
                  />
                  {/* {portal?.image ? (
                <img className="w-40" src={portal.image} alt="Logo" />
              ) : portal?.name ? (
                portal.name
              ) : (
                "DCKAP Commerce Support"
              )} */}

                </div>
              </div>
              {/* block lg:hidden md:hidden */}
              {/* <div onClick={() => setShowHamburger(prevState => !prevState)} className="md:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </div> */}
              <div className="flex items-center">
                {headerNavs.map((nav, i) => {
                  const { label, onClick, path, isPageActive } = nav;
                  const isActive = isPageActive
                    ? isPageActive(id, pathname)
                    : `/${id}${path}` === pathname;
                  return (
                    label === "Tickets" && (
                      <button
                        className={`text-sm lg:hidden md:block sm:hidden hidden text-skin-base font-light truncate font-muli hover:bg-muted ${isActive ? "bg-primary" : ""} ${commonNavCls}`}
                        onClick={onClick}
                        key={i}
                      >
                        {label}
                      </button>
                    )
                  );
                })}
                {accessToken ? (
                  <button
                    className={`border lg:hidden md:block sm:hidden hidden text-sm font-mulisemibold border-gray-300 hover:opacity-90 bg-gray-100 hover:bg-gray-50 ${commonNavCls} ${pathname.includes(submitTicketPath)
                      ? "bg-primary text-white hover:bg-primary"
                      : "bg-primary text-white hover:bg-primary"
                      }`}
                    onClick={() => navigate(submitTicketPath)}
                  >
                    Create a ticket
                  </button>
                ) : null}
                {
                  accessToken ? (
                    <div className="sm:ml-6 mr-[-20px]">
                      <Profile />
                    </div>
                  ) : (
                    <div className="md:flex grid items-center md:mx-6 mx-2">
                      <button
                        className={`hover:text-primary ${pathname.includes(loginPath) ? "text-primary text-base" : "text-primary font-mulilight"
                          }`}
                        onClick={() => navigate(loginPath)}
                      >
                        Login
                      </button>
                      <div className="md:border-r md:border-b-0 border-b border-gray-700 md:w-2 w-10 md:h-8 h-2 md:mr-2  md:ml-0 ml-2"></div>
                      <button
                        className={`hover:text-primary ${pathname.includes(signUpPath) ? "text-primary text-base" : "text-primary font-mulilight"
                          }`}
                        onClick={() => navigate(signUpPath)}
                      >
                        Sign up
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Header;
