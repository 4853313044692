import { Edit, UserCircle2 } from "lucide-react";
import Textarea from "../../common/components/Textarea";
import FileUpload from "./FileUpload";
import { useEffect, useState } from "react";
import {
  getAgoFromDate,
  handleFileUpload,
} from "../../common/helpers/commonHelpers";
import PreviewAttachment from "../../common/components/PreviewAttachment";
import PrimaryButton from "../../common/components/PrimaryButton";
import CommentForm from "./CommentForm";
import DisplayComment from "./DisplayComment";

function getDefaultForm() {
  return { comment: "", attachments: [] };
}

function udpateState(setState, update) {
  setState((prev) => ({ ...prev, ...update }));
}

function TicketComment({
  ticketComments = [],
  addComment,
  isAddingComment,
  updateComment,
  isUpdatingComment,
  setCommentId,
  commentId,
}) {
  const [form, setForm] = useState(getDefaultForm());
  const [error, setError] = useState(getDefaultForm());
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    if (!isAddingComment) {
      setForm(getDefaultForm());
    }
  }, [isAddingComment]);

  return (
    <div className="text-sm">
      <div className="py-2 mb-4 border-b border-gray-300 font-muli text-skin-base mt-4">
        Activity
      </div>

      {ticketComments.map((commentData, i) => {
        return (
          <DisplayComment
            key={i}
            commentData={commentData}
            updateComment={updateComment}
            isUpdatingComment={isUpdatingComment}
            setCommentId={setCommentId}
            commentId={commentId}
          />
        );
      })}

      <div className="flex space-x-2 w-full">
        <UserCircle2 color="gray" className="mt-1 w-6 font-mulisemibold text-primary" />
        <CommentForm
          form={form}
          error={error}
          onChangeTextArea={(e) => {
            const { name, value } = e.target;

            udpateState(setForm, { [name]: value });

            setError(getDefaultForm());
          }}
          handleFileUpload={(e) => {
            handleFileUpload(e, setFormLoading, (files) => {
              udpateState(setForm, {
                attachments: [...form.attachments, ...files],
              });

              setError(getDefaultForm());
            });
          }}
          onRemove={(index) => {
            const { attachments = [] } = form;
            const remainingFiles = attachments.filter((a, i) => i !== index);
            udpateState(setForm, { attachments: remainingFiles });
            setError(getDefaultForm());
          }}
          onSubmit={() => {
            if (!form.comment) {
              setError({ comment: "Please write a comment" });
              return;
            }

            addComment(form);
          }}
          loading={isAddingComment}
        />

        {/* <div className="w-full">
          <Textarea
            placeholder="Leave a comment"
            inputCls="min-h-[100px]"
            name="comment"
            onChange={(e) => {
              const { name, value } = e.target;

              udpateState(setForm, { [name]: value });

              setError(getDefaultForm());
            }}
            value={form.comment}
            error={error.comment}
          />

          <div className="flex justify-between w-full">
            <FileUpload
              files={form?.attachments}
              handleFileUpload={(e) => {
                handleFileUpload(e, setFormLoading, (files) => {
                  udpateState(setForm, {
                    attachments: [...form.attachments, ...files],
                  });

                  setError(getDefaultForm());
                });
              }}
              onRemove={(index) => {
                const { attachments = [] } = form;
                const remainingFiles = attachments.filter(
                  (a, i) => i !== index
                );
                udpateState(setForm, { attachments: remainingFiles });
                setError(getDefaultForm());
              }}
              id="ticketComment"
              hideNoAttachments={true}
            />

            <PrimaryButton
              btnCls="!w-fit h-fit px-4 !m-0 !mt-4"
              onClick={() => {
                if (!form.comment && !form.attachments?.length) {
                  setError({ comment: "Required" });
                  return;
                }

                addComment(form);
              }}
              btnProps={{ disabled: formLoading }}
              loading={isAddingComment}
            >
              Comment
            </PrimaryButton>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default TicketComment;
