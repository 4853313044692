import Button from "../../common/components/Button";
import TicketHeading from "./TicketHeading";

function ChildTickets({ childTicket = [] }) {
  return (
    <div className="border mt-4">
      <TicketHeading label="Child Tickets" />

      <div className="my-1 text-sm px-4">
        {childTicket.length ? (
          <>
            {childTicket.map((ticket, i) => {
              const { child_ticket_no, topic } = ticket;

              return (
                <Button
                  className="my-2 text-primary hover:border-b border-primary block"
                  key={i}
                  onClick={() => {
                    const url =
                      window.location.href + `?search=${child_ticket_no}`;
                    window.open(url, "_blank");
                  }}
                >
                  {child_ticket_no} -{" "}
                  <span className="font-semibold">{topic}</span>
                </Button>
              );
            })}
          </>
        ) : (
          <div className="font-semibold text-gray-500 text-center py-2">
            No Child Tickets
          </div>
        )}
      </div>
    </div>
  );
}

export default ChildTickets;
