import { Check, SlidersHorizontal,Filter } from "lucide-react";
import { useState } from "react";
import useDropDown from "../../common/hooks/useDropDown";
import Loading from "../../common/components/Loading";

function ShowColumns({
  columns,
  showColumns,
  toggleShowColumns,
  communityFieldsLoading,
}) {
  const [open, setOpen] = useState(false);

  const { elementRef } = useDropDown({ setOpen });

  return (
    <div className="text-sm relative">
      <div ref={elementRef}>
        <button
          className="flex items-center border text-sm font-mulisemibold text-skin-primary border-primary rounded-md bg-muted px-4 py-[10px]"
          onClick={() => setOpen((prevState) => !prevState)}
        >
          <Filter size={14} className="mr-1" />
          Filter
        </button>

        {open ? (
          <DisplayColumns
            columns={columns}
            showColumns={showColumns}
            toggleShowColumns={toggleShowColumns}
            communityFieldsLoading={communityFieldsLoading}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ShowColumns;

function DisplayColumns({
  columns,
  showColumns,
  toggleShowColumns,
  communityFieldsLoading,
}) {
  return (
    <div className="menu bg-white absolute top-11 right-0 left-0 w-40 rounded-md border z-20 max-h-[260px] overflow-y-auto">
      {columns.map((c, i) => {
        const { label, value } = c;

        const isSelected = showColumns.includes(value);

        return (
          <div
            key={i}
            onClick={() => toggleShowColumns(value)}
            className="cursor-pointer text-skin-base text-xs font-mulilight flex items-center space-x-2 py-2 truncate hover:bg-muted"
          >
            <div className="w-[24%]">
              {isSelected ? <Check size={16} className="mx-3 font-mulilight" /> : null}
            </div>

            {label}
          </div>
        );
      })}

      {communityFieldsLoading ? <Loading cls="m-4" /> : null}
    </div>
  );
}
