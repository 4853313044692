import { useEffect, useState } from "react";
import Modal from "../../common/components/Modal";
import { validateAdditionalFields } from "../../common/helpers/commonHelpers";
import AdditionalFieldsForm from "./AdditionalFieldsForm";
import PrimaryButton from "../../common/components/PrimaryButton";
import Button from "../../common/components/Button";

function getDefaultFormValues(ticketCustomFields = []) {
  const obj = {};

  ticketCustomFields.forEach((c) => {
    const { customfield_id, customfield_values } = c;
    obj[customfield_id] = customfield_values;
  });

  return obj;
}

function UpdateCustomFields({
  edit,
  setEdit,
  customFields = [],
  ticketCustomFields,
  updateCustomFields,
  isUpdatingCustomFields,
}) {
  const [form, setForm] = useState(getDefaultFormValues(ticketCustomFields));
  const [error, setError] = useState({});
  const [submitBtnClickedOnce, setSubmitBtnClickedOnce] = useState(false);
  // useEffect(() => {
  //   setEdit(isUpdatingCustomFields);
  // }, [isUpdatingCustomFields]);
  return (
    <Modal
      isOpen={edit}
      onClose={() => setEdit(false)}
      contentCls="md:!w-[58%] w-full !max-w-none"
    >
      {customFields?.length ? (
        <div>
          <div className="text-lg py-2 mb-4 border-b border-gray-300 font-semibold text-gray-600">
            Additional Fields
          </div>

          <form
            onSubmit={(e) => {
              

              e.preventDefault();
              setSubmitBtnClickedOnce(true);

              const errorData = validateAdditionalFields(form, customFields);

              setError(errorData?.error);

              if (errorData.havingError) {
                return;
              }

              updateCustomFields(form);
            }}
          >
            <div className="grid md:grid-cols-2 gap-[20px] items-center">
              {customFields.map((field, i) => {
                return (
                  <AdditionalFieldsForm
                    key={i}
                    field={field}
                    additionalFields={form}
                    error={error[field.id] || {}}
                    updateAdditionalFields={(id, value, field) => {
                      const prevData = { ...form, [id]: value };
                      if (!value) {
                        delete prevData[id];
                      }
                      setForm(prevData);
                      const errorData = validateAdditionalFields(prevData, [
                        field,
                      ]);
                      setError((prev) => ({
                        ...prev,
                        [id]: errorData.error[id],
                      }));
                    }}
                  />
                );
              })}
            </div>

            <div className="flex justify-center items-center space-x-2 mt-20">
              <PrimaryButton
                type="submit"
                btnCls="!w-fit px-4 !m-0"
                btnProps={{ disabled: !Object.keys(form)?.length }}
                marginTop="!mt-0"
                loading={isUpdatingCustomFields}
              >
                Submit
              </PrimaryButton>

              <Button
                className="px-4 py-2 rounded-md border border-red-600 hover:bg-red-50 text-red-600"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      ) : null}
    </Modal>
  );
}

export default UpdateCustomFields;
