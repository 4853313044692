import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const AppContext = createContext();

export const useApp = () => {
  return useContext(AppContext);
};

// *** we are not using this context now
function AppProvider({ children }) {
  const [baseName, setBaseName] = useState("");
  const [portal, setPortal] = useState("");

  // useEffect(() => {
  //   const parsedData = window.location.pathname.split("/support/");

  //   setBaseName(parsedData[1]);
  // }, []);

  return (
    <AppContext.Provider value={{ baseName, setBaseName, portal, setPortal }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
