import { basePath } from "../helpers/commonHelpers";

function PortalDisabled({ error }) {
  return (
    <div className="h-screen flex justify-center items-center text-gray-700">
      <div className="bg-white rounded-md border shadow-md p-8 px-12 flex flex-col justify-center items-center">
        <img
          src={"/support/images/not-found.jpg"}
          alt="not-found"
          className="w-[300px]"
        />

        <div>
          <div className="font-semibold text-2xl text-gray-500">
            The page you are looking for is{" "}
            <span className="text-blue-600 font-black">
              {error === "Under Maintenance"
                ? "Under Maintenance."
                : "not found."}
            </span>
          </div>

          <div className="text-md mt-2 text-center text-gray-500">
            It looks like the portal is{" "}
            {error === "Under Maintenance"
              ? "Under Maintenance."
              : "not found."}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortalDisabled;
