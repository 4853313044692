import { AlertCircle, CheckCircle, X, XCircle } from "lucide-react";

const alertConfig = {
  success: {
    Icon: CheckCircle,
    iconCls: "text-green-600",
  },
  error: {
    Icon: XCircle,
    iconCls: "text-red-600",
  },
  warning: {
    Icon: AlertCircle,
    iconCls: "text-amber-600",
  },
};

function Alert({ showPopup, onClose }) {
  const { type, message, show } = showPopup;

  const { Icon = () => {}, iconCls = "" } = alertConfig[type] || {};

  return (
    <div
      className={`${
        show ? "translate-y-4 opacity-100" : "-translate-y-8 opacity-0"
      }  transition duration-300 ease-in-out bg-white flex items-center p-2 px-4 fixed top-0 left-[40%] z-[999] m-4 shadow-md border rounded-md text-gray-700`}
    >
      <Icon className={`w-5 h-5 mr-3 ${iconCls}`} />
      <div className="max-w-[280px] truncate">{message}</div>

      <X
        size={14}
        className="ml-4 cursor-pointer hover:text-red-600"
        onClick={onClose}
      />
    </div>
  );
}

export default Alert;
