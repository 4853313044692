import { useNavigate } from "react-router-dom";
import TicketForm from "../components/TicketForm";
import { usePopup } from "../../common/contexts/PopupContext";
import useTicket from "../hooks/useTicket";
import useApiData from "../../common/hooks/useApiData";
import React, { useEffect, useState } from "react";
import useCustomNavigate from "../../common/hooks/useCustomNavigate";

function TicketFormContainer({ setRefreshData }) {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isReset, setIsReset] = useState(false)
  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); // Assuming lg breakpoint is 1024px
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, [isLargeScreen]);

  const navigate = useCustomNavigate();

  const { toast } = usePopup();

  const { createTicket, getCommunityFields } = useTicket();

  const {
    data,
    mutate: getCommunityFieldsMutate,
    loading: communityFieldsLoading,
  } = useApiData({
    onError: (error) => toast(error?.response?.data?.message, "error"),
  });

  const { mutate, loading } = useApiData({
    onSuccess: (res) => {
      const { data = {} } = res;
      const { status } = data;

      let msg = "Ticket created successfully";

      if (status === "success") {
        if (isLargeScreen) {
          setIsReset(true)
          // navigate(0);
          setRefreshData(true)
          // mutate(getTickets(payload, page));
        } else {
          navigate("/tickets");
        }
      }
      else {
        msg = "Unable to create ticket";
      }

      toast(msg, status);
    },
    onError: (error) => {
      toast(error?.response?.data?.message, "error");
    },
  });

  useEffect(() => {
    getCommunityFieldsMutate(getCommunityFields());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dependencyDropdownData = data?.data || [];
  return (
    <div className={`lg:w-[35%] w-full max-w-[1440px] lg:px-[0px] sm:px-[40px] px-[10px]`}>
      <TicketForm
        onSubmit={(payload) => mutate(createTicket(payload))}
        dependencyDropdownData={dependencyDropdownData}
        communityFieldsLoading={communityFieldsLoading}
        loading={loading}
        isReset={isReset}
        setIsReset={setIsReset}
      />
    </div>
  );
}

export default TicketFormContainer;
