import TicketFormContainer from "../../ticket/container/TicketFormContainer";
import TicketsContainer from "../../ticket/container/TicketsContainer";
import Features from "../components/Features";
import KnowledgeBase from "../components/KnowlegdeBase";
import React, { useState, useEffect } from "react";

function HomeContainer() {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [refreshData, setRefreshData] = useState(false)
  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 1025); // Assuming lg breakpoint is 1024px
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);
  return (
    <div className="mt-2 lg:flex items-start max-w-[1440px] w-full lg:px-[40px] mx-auto gap-x-6 justify-center">
      {/* <Features />
      <KnowledgeBase /> */}
      <TicketFormContainer setRefreshData={setRefreshData} />
      {isLargeScreen && <TicketsContainer refreshData={refreshData} setRefreshData={setRefreshData} />}
    </div>
  );
}

export default HomeContainer;
