import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { checkAuth } from "../../common/helpers/commonHelpers";
import { useEffect } from "react";
import useCustomNavigate from "../../common/hooks/useCustomNavigate";

function LoginContainer() {
  const navigate = useCustomNavigate();

  useEffect(() => {
    if (checkAuth()) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoginForm />;
}

export default LoginContainer;
