import { useEffect, useState } from "react";
import QuillInput from "../../common/components/QuillInput";
import Button from "../../common/components/Button";
import { isQuillHavingText } from "../../common/helpers/commonHelpers";
import Loading from "../../common/components/Loading";
import { Edit } from "lucide-react";

function TicketContent({ ticketContent, updateTicket, isUpdating }) {
  const [content, setContent] = useState(ticketContent);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setContent(ticketContent);
  }, [ticketContent]);

  if (isUpdating) {
    return <Loading />;
  }

  return (
    <div className="text-sm">
      {edit ? (
        <div>
          <QuillInput
            value={content}
            onChange={(e) => setContent(e.target.value)}
            name="content"
          />

          <div className="flex items-center space-x-2 my-4 font-semibold text-sm">
            <Button
              className="bg-primary text-white font-mulisemibold hover:opacity-90 px-4 py-2 rounded-md"
              onClick={() => {
                updateTicket(content);
                setEdit(false);
              }}
            >
              Save
            </Button>

            <Button
              className="text-gray-500 hover:text-skin-textred font-mulilight px-4 py-2 rounded-md"
              onClick={() => {
                setContent(ticketContent);
                setEdit(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="py-2 hover:bg-[#F4FDFF] rounded-md border-primary px-2 text-base font-mulisemibold text-skin-base" onClick={() => setEdit(true)}>
          {isQuillHavingText(content) ? (
            <pre
              style={{
                display: "flex",
                fontFamily: "inherit",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <span className="text-sm pl-3 pt-[7px] cursor-pointer" >
                <Edit size={15} color="#005f73" />
              </span>
            </pre>
          ) : (
            "Add a description"
          )}
        </div>
      )
      }
    </div >
  );
}

export default TicketContent;
