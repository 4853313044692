import {
  ArrowDown,
  ArrowDownUp,
  ArrowUp,
  EyeOff,
  MoveDown,
  MoveUp,
} from "lucide-react";
import { useState } from "react";
import useDropDown from "../../common/hooks/useDropDown";
import { useSearchParams } from "react-router-dom";

function Sort({ label, onHide, onSort, name }) {
  const [open, setOpen] = useState(false);
  const { elementRef } = useDropDown({ setOpen });

  const [searchParams] = useSearchParams();

  const sort = searchParams.get("sort");
  const order = searchParams.get("order");

  const isSorted = sort === name;

  const SortedOrderIcon = order === "asc" ? ArrowUp : ArrowDown;

  return (
    <div className="relative text-gray-700" ref={elementRef}>
      <div
        className="flex items-center space-x-1 cursor-pointer hover:text-primary w-fit p-2 pl-0 rounded-md"
        onClick={() => setOpen(true)}
      >
        <div className="text-skin-base text-base font-muli font-normal">{label}</div>

        {isSorted ? <SortedOrderIcon size={14} fontFamily="Muli light" color="#182444" /> : <ArrowDownUp size={14} fontFamily="Muli light" color="#182444"/>}
      </div>

      {open ? (
        <SortOptions
          onClose={() => setOpen(false)}
          onHide={onHide}
          onSort={(order) => onSort({ ...order, sort: name })}
          sortValue={{ sort, order }}
          name={name}
        />
      ) : null}
    </div>
  );
}

export default Sort;

const sortOptions = [
  { label: "Asc", Icon: MoveUp, value: "asc" },
  { label: "Desc", Icon: MoveDown, value: "desc" },
];

function SortOptions({ onClose, onHide, onSort, sortValue, name }) {
  return (
    <div className="menu absolute z-10 top-9 left-0 bg-white rounded-md shadow-md border w-24">
      {sortOptions.map((option, i) => {
        const isActive =
          name === sortValue.sort && option.value === sortValue.order;

        return (
          <DisplayOption
            {...option}
            key={i}
            onClick={() => {
              onSort({ order: option.value });
              onClose();
            }}
            isActive={isActive}
          />
        );
      })}

      {onHide ? (
        <div className="pt-1 mt-1 border-t border-gray-300">
          <DisplayOption
            label="Hide"
            Icon={EyeOff}
            onClick={() => {
              onHide();
              onClose();
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

function DisplayOption({ label, Icon, onClick, isActive }) {
  return (
    <button
      className={`flex items-center text-sm px-2 py-1.5 hover:bg-muted font-mulilight text-skin-base w-full ${
        isActive ? "text-primary" : ""
      }`}
      onClick={() => onClick()}
    >
      <Icon size={14}  /> <div className="pl-2">{label}</div>
    </button>
  );
}
