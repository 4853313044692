import { useEffect, useState } from "react";
import Input from "../../common/components/Input";
// import { Input } from "../../common/components/Input";
import CcEmails from "./CcEmails";
import SelectInput from "../../common/components/SelectInput";
import {
  handleFileUpload,
  isQuillHavingText,
  isValidEmail,
  priorities,
  udpateState,
  validateAdditionalFields,
  validateRequiredInput,
} from "../../common/helpers/commonHelpers";
import PrimaryButton from "../../common/components/PrimaryButton";
import AdditionalFieldsForm from "./AdditionalFieldsForm";
import FileUpload from "./FileUpload";
import QuillInput from "../../common/components/QuillInput";
import Loading from "../../common/components/Loading";
import CreatableSelect from "react-select/creatable";
import useCustomNavigate from "../../common/hooks/useCustomNavigate";
import { checkAuth } from "../../common/helpers/commonHelpers";
import { useLocation } from "react-router-dom";
function validateFormData(form, dependencyDropdownData) {
  const { topic, priority, additionalFields, ticketContent } = form;

  const topicError = validateRequiredInput(topic, "Title");
  const priorityError = validateRequiredInput(priority, "Priority");
  const contentError = isQuillHavingText(ticketContent, "Content")
    ? ""
    : "Content is required";
  const additionalFieldsError = validateAdditionalFields(
    additionalFields,
    dependencyDropdownData
  );

  return {
    topic: topicError,
    priority: priorityError,
    ticketContent: contentError,
    additionalFields: additionalFieldsError.error,
    havingError:
      topicError ||
      priorityError ||
      contentError ||
      additionalFieldsError.havingError,
  };
}

function handleSetError(name, error, setError, fieldId = null) {
  if (fieldId) {
    // Handle nested errors for additional fields
    setError((prev) => ({
      ...prev,
      additionalFields: {
        ...prev.additionalFields,
        [fieldId]: error[fieldId] ? error[fieldId] : "",
      },
    }));
  } else {
    // Handle regular field errors
    setError((prev) => ({
      ...prev,
      [name]: error ? error : "",
    }));
  }
}


function hasFormSubmit(
  e,
  form,
  error,
  setError,
  setSubmitBtnClickedOnce,
  createTicket,
  dependencyDropdownData
) {
  e.preventDefault();

  setSubmitBtnClickedOnce(true);

  const errorData = validateFormData(form, dependencyDropdownData);

  setError(errorData);

  if (errorData.havingError) {
    return;
  }

  createTicket();
  setSubmitBtnClickedOnce(false);
}

function handleInputChange(
  e,
  submitBtnClickedOnce,
  form,
  setForm,
  setError,
  dependencyDropdownData
) {
  const { value, name } = e.target;
  const payload = { [name]: value };
  udpateState(setForm, payload);
  if (name === "topic" || name === "priority") {
    const errorData = validateRequiredInput(value);
    handleSetError(name, errorData, setError);
  }
  if (name === "ticketContent") {
    const contentError = isQuillHavingText(value, "Content")
      ? ""
      : "This field is required";
    handleSetError(name, contentError, setError);
  }
}


function getDefaultForm() {
  return {
    topic: "",
    priority: "",
    ticketContent: "",
    additionalFields: {},
    attachments: [],
    cc: [],
  };
}

function updateAdditionalFields(
  id,
  value,
  field,
  setForm,
  form,
  submitBtnClickedOnce,
  setError,
  dependencyDropdownData
) {
  const prevData = { ...form };
  prevData.additionalFields = { ...prevData.additionalFields, [id]: value };
  if (!value) {
    delete prevData.additionalFields[id];
  }
  setForm(prevData);
  const errorData = validateAdditionalFields(prevData.additionalFields, [
    field,
  ]);
  handleSetError("additionalFields", errorData.error, setError, id);
}

function TicketForm({
  onSubmit,
  dependencyDropdownData,
  communityFieldsLoading,
  loading,
  isReset,
  setIsReset
}) {
  const [form, setForm] = useState(getDefaultForm());
  const [error, setError] = useState(getDefaultForm());

  const [submitBtnClickedOnce, setSubmitBtnClickedOnce] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [ccError, setCCError] = useState("");

  useEffect(() => {
    if (!loading && isReset) {
      setForm(getDefaultForm())
      setIsReset(false)
    }
  }, [isReset]);

  const navigate = useCustomNavigate();
  const accessToken = checkAuth()
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  return (
    <>
      <div className="md:hidden flex justify-between gap-2 items-center mt-24 w-full">
        <button
          className={`border w-[50%] text-sm font-mulisemibold rounded-md py-2 px-2 hover:opacity-90 
          ${currentRoute === "/tickets" ? "bg-primary text-white" : "bg-secondarylight border-primary text-primary"
            }`}
          onClick={() => navigate("/tickets")}>Tickets</button>
        {accessToken ? (
          <button
            className={`border  w-[50%] text-sm font-mulisemibold rounded-md py-2 px-2  hover:opacity-90
             ${currentRoute === "/create-ticket" || currentRoute === "/" ? "bg-primary text-white" : "bg-secondarylight border-primary text-primary"
              }`}
            onClick={() => navigate("/create-ticket")}
          >
            Create a ticket
          </button>
        ) : null}
      </div>
      <div className="md:mt-24 mt-6 mb-10 rounded-md bg-white shadow-mainshadow px-3 py-6 text-gray-700 border">
        <div className="font-mulisemibold text-2xl text-center text-skin-base">Create a ticket</div>
        <form
          noValidate
          onSubmit={(e) =>
            hasFormSubmit(
              e,
              form,
              error,
              setError,
              setSubmitBtnClickedOnce,
              () => onSubmit(form),
              dependencyDropdownData
            )
          }
          className="flex flex-col space-y-7 mx-auto pt-2 md:w-11/12 w-full"
        >
          {/* <div className="grid md:grid-cols-2 gap-4"> */}
          <div className="mt-3">
            <Input
              // type="text"
              label="Title"
              name="topic"
              inputCls="focus:border-blue-gray-200 border outline-none text-skin-base font-mulisemibold"
              required={true}
              onChange={(e) => {
                handleInputChange(
                  e,
                  submitBtnClickedOnce,
                  form,
                  setForm,
                  setError,
                  dependencyDropdownData
                );
              }}
              value={form.topic}
            // error={error?.topic}
            />
            {error?.topic ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error?.topic}</div> : null}
          </div>
          <div className="mb-3">
            <SelectInput
              label="Priority"
              name="priority"
              placeholder={"Select priority"}
              inputCls="focus:border-primary border outline-none text-skin-base font-mulisemibold"
              options={priorities}
              required={true}
              onChange={(e) =>
                handleInputChange(
                  e,
                  submitBtnClickedOnce,
                  form,
                  setForm,
                  setError,
                  dependencyDropdownData
                )
              }
              value={form.priority}
              error={error?.priority}
            />
            {error?.priority ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error?.priority}</div> : null}
          </div>
          {/* </div> */}

          <QuillInput
            label="Content"
            name="ticketContent"
            required={true}
            onChange={(e) => {
              handleInputChange(
                e,
                submitBtnClickedOnce,
                form,
                setForm,
                setError,
                dependencyDropdownData
              );
            }}
            value={form.ticketContent}
            error={error?.ticketContent}
          />

          <div>
            <div className={`my-1 font-mulisemibold text-sm text-skin-base`}>CC</div>
            <CcEmails
              handleInputChange={(payload) => {
                handleInputChange(
                  payload,
                  submitBtnClickedOnce,
                  form,
                  setForm,
                  setError,
                  dependencyDropdownData
                );
              }}
              form={form}
              setCCError={setCCError}
            />
            {/* <CreatableSelect
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border: 0,
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#005f73"
                  },
                  border: state.isFocused ? "1px solid #005f73" : "1px solid #DADCE0", // Change border color when focused
                  borderRadius: "4px",
                  outline: state.isFocused ? "none" : "",
                  fontFamily: "Muli light",
                  fontSize: "text-skin-base",
                  backgroundColor: "white",
                }),
                option: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  background: isFocused
                    ? '#F4FDFF'
                    : isSelected
                      ? '#F4FDFF'
                      : "#ffffff",
                  zIndex: 1
                }),
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: "#d6f9ff",
                  };
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: "#182444",
                  fontFamily: "Muli light"
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  color: "#9f9f9f",
                  ':hover': {
                    backgroundColor: "#d6f9ff",
                    color: '#f98c76',
                  },
                }),
                // other styles as needed
              }}
              isMulti
              value={
                form?.cc?.length
                  ? form?.cc.map((c) => ({ label: c, value: c }))
                  : null
              }
              options={[]}
              onChange={(val, actionData) => {
                const { action, option } = actionData;

                if (action === "create-option" && !isValidEmail(option?.value)) {
                  setCCError("Invalid Email");
                } else {
                  setCCError(false);
                }

                const validEmails = val?.filter((email) =>
                  isValidEmail(email.value)
                );

                const payload = {
                  target: {
                    name: "cc",
                    value: validEmails?.length
                      ? validEmails.map((c) => c.value)
                      : [],
                  },
                };

                handleInputChange(
                  payload,
                  submitBtnClickedOnce,
                  form,
                  setForm,
                  setError,
                  dependencyDropdownData
                );
              }}
              className=""
              placeholder="name@example.com"
              formatCreateLabel={(data) => {
                return (
                  <div className="text-sm font-mulilight">
                    Add {data}
                  </div>
                );
              }}

            /> */}

            {ccError && (
              <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{ccError}</div>
            )}
          </div>

          {dependencyDropdownData?.length ? (
            <div className="py-4">
              <div className="pt-2 mb-4 font-mulisemibold text-sm text-skin-base">
                Additional Fields
              </div>

              <div className="grid gap-y-7">
                {dependencyDropdownData.map((field, i) => {
                  return (
                    <AdditionalFieldsForm
                      key={i}
                      field={field}
                      additionalFields={form.additionalFields}
                      error={error.additionalFields[field.id] || {}}
                      updateAdditionalFields={(id, value, field) => {
                        updateAdditionalFields(
                          id,
                          value,
                          field,
                          setForm,
                          form,
                          submitBtnClickedOnce,
                          setError,
                          dependencyDropdownData
                        );
                      }}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <div>{communityFieldsLoading ? <Loading /> : null}</div>
          )}

          <FileUpload
            labelStyles="font-mulisemibold text-sm text-skin-base"
            files={form.attachments || []}
            handleFileUpload={(e) => {
              handleFileUpload(e, setFormLoading, (files) =>
                udpateState(setForm, {
                  attachments: [...form.attachments, ...files],
                })
              );
            }}
            onRemove={(index) => {
              const { attachments = [] } = form;

              const remainingFiles = attachments.filter((a, i) => i !== index);

              udpateState(setForm, { attachments: remainingFiles });
            }}
            label="Add Attachments"
            id="ticketForm"
          />

          <PrimaryButton
            type="submit"
            btnCls="w-full px-7 md:text-sm text-base py-2"
            loading={formLoading || loading}
          >
            Create Ticket
          </PrimaryButton>
        </form>
      </div>
    </>

    // <div className="lg:w-[800px] w-11/12  mx-auto mt-24 mb-10 rounded-md shadow-mainshadow px-2 py-6 text-gray-700 border">
    //   <div className="font-semibold text-2xl pl-10">Create a ticket</div>
    //   <form
    //     onSubmit={(e) =>
    //       hasFormSubmit(
    //         e,
    //         form,
    //         error,
    //         setError,
    //         setSubmitBtnClickedOnce,
    //         () => onSubmit(form),
    //         dependencyDropdownData
    //       )
    //     }
    //     className="flex flex-col space-y-4 mx-auto pt-2 md:w-11/12 w-full"
    //   >
    //     <div className="grid md:grid-cols-2 gap-4">
    //       <Input
    //         label="Title"
    //         name="topic"
    //         required={true}
    //         onChange={(e) => {
    //           handleInputChange(
    //             e,
    //             submitBtnClickedOnce,
    //             form,
    //             setForm,
    //             setError,
    //             dependencyDropdownData
    //           );
    //         }}
    //         value={form.topic}
    //         error={error?.topic}
    //       />

    //       <SelectInput
    //         label="Priority"
    //         name="priority"
    //         options={priorities}
    //         required={true}
    //         onChange={(e) => {
    //           handleInputChange(
    //             e,
    //             submitBtnClickedOnce,
    //             form,
    //             setForm,
    //             setError,
    //             dependencyDropdownData
    //           );
    //         }}
    //         // className={""}
    //         value={form.priority}
    //         error={error?.priority}
    //       />
    //     </div>

    //     <QuillInput
    //       label="Content"
    //       name="ticketContent"
    //       required={true}
    //       onChange={(e) => {
    //         handleInputChange(
    //           e,
    //           submitBtnClickedOnce,
    //           form,
    //           setForm,
    //           setError,
    //           dependencyDropdownData
    //         );
    //       }}
    //       value={form.ticketContent}
    //       error={error?.ticketContent}
    //     />

    //     <div>
    //       <div className={`my-1`}>CC</div>
    //       <CreatableSelect
    //         isMulti
    //         value={
    //           form?.cc?.length
    //             ? form?.cc.map((c) => ({ label: c, value: c }))
    //             : null
    //         }
    //         options={[]}
    //         onChange={(val, actionData) => {
    //           const { action, option } = actionData;

    //           if (action === "create-option" && !isValidEmail(option?.value)) {
    //             setCCError("Invalid Email");
    //           } else {
    //             setCCError(false);
    //           }

    //           const validEmails = val?.filter((email) =>
    //             isValidEmail(email.value)
    //           );

    //           const payload = {
    //             target: {
    //               name: "cc",
    //               value: validEmails?.length
    //                 ? validEmails.map((c) => c.value)
    //                 : [],
    //             },
    //           };

    //           handleInputChange(
    //             payload,
    //             submitBtnClickedOnce,
    //             form,
    //             setForm,
    //             setError,
    //             dependencyDropdownData
    //           );
    //         }}
    //         placeholder="name@example.com"
    //         formatCreateLabel={(data) => {
    //           console.log("data", data);
    //           return `Add ${data}`;
    //         }}
    //       />

    //       {ccError && (
    //         <div className="text-red-600 pt-2 text-sm">{ccError}</div>
    //       )}
    //     </div>

    //     {dependencyDropdownData?.length ? (
    //       <div className="py-4">
    //         <div className="py-2 mb-4 border-b border-gray-300 font-semibold text-gray-600">
    //           Additional Fields
    //         </div>

    //         <div className="grid grid-cols-2 gap-6 items-center">
    //           {dependencyDropdownData.map((field, i) => {
    //             return (
    //               <AdditionalFieldsForm
    //                 key={i}
    //                 field={field}
    //                 additionalFields={form.additionalFields}
    //                 error={error.additionalFields[field.id] || {}}
    //                 updateAdditionalFields={(id, value, field) => {
    //                   updateAdditionalFields(
    //                     id,
    //                     value,
    //                     field,
    //                     setForm,
    //                     form,
    //                     submitBtnClickedOnce,
    //                     setError,
    //                     dependencyDropdownData
    //                   );
    //                 }}
    //               />
    //             );
    //           })}
    //         </div>
    //       </div>
    //     ) : (
    //       <div>{communityFieldsLoading ? <Loading /> : null}</div>
    //     )}

    //     <FileUpload
    //       files={form.attachments || []}
    //       handleFileUpload={(e) => {
    //         handleFileUpload(e, setFormLoading, (files) =>
    //           udpateState(setForm, {
    //             attachments: [...form.attachments, ...files],
    //           })
    //         );
    //       }}
    //       onRemove={(index) => {
    //         const { attachments = [] } = form;

    //         const remainingFiles = attachments.filter((a, i) => i !== index);

    //         udpateState(setForm, { attachments: remainingFiles });
    //       }}
    //       label="Add Attachments"
    //       id="ticketForm"
    //     />

    //     <PrimaryButton
    //       type="submit"
    //       btnCls="!w-fit px-4"
    //       loading={formLoading || loading}
    //     >
    //       Create Ticket
    //     </PrimaryButton>
    //   </form>
    // </div>
  );
}

export default TicketForm;
