import { CornerDownRight } from "lucide-react";
import TicketHeading from "./TicketHeading";
import { useState } from "react";
import UpdateCustomFields from "./UpdateCustomFields";
import { getLevelFieldsMargin } from "../../common/helpers/commonHelpers";

function TicketCustomFields({
  ticketCustomFields = [],
  customFields,
  updateCustomFields,
  isUpdatingCustomFields,
  edit,
  setEdit,
}) {
  // const [open, setOpen] = useState(false);
  return (
    <div className="border mt-4">
      <TicketHeading
        label="Custom Fields"
        onEdit={ticketCustomFields.length ? () => setEdit(true) : null}
      />

      <div className="text-sm">
        {ticketCustomFields?.length ? (
          <>
            {ticketCustomFields.map((field, i) => {
              const { customfield_name } = field;
              const Comp =
                typeof customfield_name === "string"
                  ? DisplayOtherField
                  : DisplayDependencyDropdownField;

              return (
                <div key={i} className="py-2 px-4 border-b text-skin-base font-muli text-base ">
                  <Comp field={field} />
                </div>
              );
            })}
          </>
        ) : (
          <div className="font-mulilight text-gray-500 text-sm text-center py-2">
            No Custom fields
          </div>
        )}
      </div>

      {ticketCustomFields.length ? (
        <UpdateCustomFields
          edit={edit}
          setEdit={setEdit}
          ticketCustomFields={ticketCustomFields}
          customFields={customFields}
          updateCustomFields={updateCustomFields}
          isUpdatingCustomFields={isUpdatingCustomFields}
        />
      ) : null}
    </div>
  );
}

export default TicketCustomFields;

function DisplayOtherField({ field = {} }) {
  const { customfield_name, customfield_values } = field;

  return (
    <div>
      {customfield_name} : {" "}
      <span className="text-sm font-mulilight text-skin-base">{customfield_values}</span>
    </div>
  );
}

function DisplayDependencyDropdownField({ field = {} }) {
  const { customfield_name, customfield_values } = field;
  return (
    <div className="overflow-x-auto">
      {Object.entries(customfield_values).map(([key, value], i) => {
        return (
          <div
            key={i}
            className="flex items-center space-x-1 py-[2px] w-full"
            style={{ marginLeft: getLevelFieldsMargin(i) + "px" }}
          >
            {i !== 0 ? <CornerDownRight size={18} color="gray" /> : null}

            <div className="">
              {customfield_name[key]} : {" "}
              <span className="text-sm font-mulilight text-skin-base">{value}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
