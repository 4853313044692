import React, { useMemo } from "react";
import Modal from "./Modal";
import { ArrowDownToLine } from "lucide-react";

function PreviewAttachment({ openModal, setOpenModal }) {
  const attachment = openModal || {};

  const { link, type: attachmentType } = attachment;

  const type = useMemo(() => {
    if (!link) {
      return "";
    }

    if (attachmentType) {
      return attachmentType;
    }

    return link.split(/[#?]/)[0].split(".").pop().trim();
  }, [link, attachmentType]);

  return (
    <div>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        contentCls="md:min-w-[800px]"
      >
        {" "}
        <div className="relative dialog-content bg-white rounded-lg shadow">
          <div className="text-center flex items-start justify-between p-4 rounded-t">
            <div>
              <h3 className="text-xl font-semibold text-gray-900  ">
                Document
              </h3>
            </div>
            <div className="flex items-center">
              <a
                href={link}
                download
                className="py-1 px-2 font-semibold bg-main text-white rounded-sm text-sm"
                target="__blank"
              >
                <ArrowDownToLine size={16} className="mr-2" />
                Download
              </a>
            </div>
          </div>
          <div style={{ height: "100%" }}>
            {/* <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={docs}
            /> */}

            {["doc", "docx", "xls", "xlsx"].includes(type) ? (
              <embed
                className="w-[100%]"
                style={{ height: "450px" }}
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                  link
                )}`}
              ></embed>
            ) : ["jpg", "jpeg", "png", "gif"].includes(type) ? (
              <img
                className="w-[100%]"
                style={{ height: "auto" }}
                src={`${link}`}
                alt="your attachment"
              ></img>
            ) : ["pdf"].includes(type) ? (
              <embed
                type="application/pdf"
                className="w-[100%]"
                style={{ height: "450px" }}
                src={`${link}`}
              ></embed>
            ) : ["mp4", "3gp"].includes(type) ? (
              <video controls width="100%" style={{ height: "auto" }}>
                <source src={link} type={`video/${type}`} />
                Sorry, your browser doesn't support videos.
              </video>
            ) : (
              <embed
                type={`application/${type}`}
                className="w-[100%]"
                style={{ height: "450px" }}
                src={`${link}`}
              ></embed>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PreviewAttachment;
