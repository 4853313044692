import { useState } from "react";
import PreviewAttachment from "../../common/components/PreviewAttachment";
import FileUpload from "./FileUpload";

function ShowAttachments({ attachments = [] }) {
  const [openAttachmentPreview, setOpenAttachmentPreview] = useState(false);

  return (
    <div className="mt-2">
      <FileUpload
        files={attachments}
        handleFileUpload={() => {}}
        onRemove={(index) => {}}
        onView={(link) => setOpenAttachmentPreview({ link })}
        onDownload={() => {}}
        disabled={true}
        label="Attachments"
        labelStyles="font-muli text-skin-base text-base"
        id="viewTicket"
      />

      <PreviewAttachment
        openModal={openAttachmentPreview}
        setOpenModal={setOpenAttachmentPreview}
        link={openAttachmentPreview}
      />
    </div>
  );
}

export default ShowAttachments;
