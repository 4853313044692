import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getLocalStorage } from "../helpers/browserStorage";
import apiInstance from "../helpers/apiInstance";
import useApiData from "../hooks/useApiData";
import { usePopup } from "./PopupContext";
import { useApp } from "./AppContext";
import { getOrigin } from "../helpers/commonHelpers";
import Loading from "../components/Loading";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

function getUserInfo(payload) {
  return () => apiInstance.post(`/auth/getUserInfo`, payload);
}

function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [accessToken, setAccessToken] = useState(
    getLocalStorage("accessToken")
  );

  const { baseName } = useApp();
  const { toast } = usePopup();

  const { data, mutate, loading } = useApiData({
    onSuccess: (response) => {
      const { status, message, user, portal } = response?.data || {};

      if (status === "success") {
        setUser({ ...user, portal });
      } else {
        // toast(message || "Unable to get user info", "error");
      }
    },
    onError: (error) => {
      // toast(
      //   error?.response?.data?.message || "Unable to get user info",
      //   "error"
      // );
    },
  });

  useEffect(() => {
    if (baseName && accessToken && !loading) {
      const url = getOrigin() + "/support/" + baseName;
      mutate(getUserInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, baseName]);

  return (
    <AuthContext.Provider
      value={{ user, setUser, accessToken, setAccessToken }}
    >
      {loading ? <FetchingUserDetails /> : children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

function FetchingUserDetails() {
  return (
    <div className="w-screen h-screen text-gray-500 flex flex-col space-y-4 justify-center items-center">
      <div className="text-xl font-mulilight text-gray-500">Fetching your details</div>
      <Loading />
    </div>
  );
}
