import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { debounce, isValidEmail } from "../../common/helpers/commonHelpers";
import useTicket from "../hooks/useTicket";
import useApiData from "../../common/hooks/useApiData";
import { useAuth } from "../../common/contexts/AuthContext";
const CcEmails = ({ handleInputChange, form, setCCError }) => {
  const [emails, setEmails] = useState([]);
  const { getSimilarEmails } = useTicket();
  const { user } = useAuth();
  const { data, mutate } = useApiData({
    onSuccess: (res) => {
      const similarEmails = res?.data?.data || [];
      if (similarEmails.length === 0) {
        setEmails([]);
        return;
      }
      similarEmails.map((email) => {
        // checking if the value is already present
        if (!emails.some((item) => item.value === email)) {
          setEmails((prevEmails) => [
            ...prevEmails,
            { label: email, value: email },
          ]);
        }
        return null;
      });
    },
    onError: (error) => {
      console.log("unable to get similar emails");
    },
  });
  const fetchEmails = (val) => {
    if (val.length < 3) {
      setEmails([]);
      return;
    }
    if (val.length >= 3) {
      mutate(getSimilarEmails(val));
    }
  };
  const handleOnCHange = (val, actionData) => {
    const { action, option } = actionData;
    if (action === "create-option" && !isValidEmail(option?.value.trim())) {
      setCCError("Invalid Email");
    } else if (option?.value === user.email) {
      setCCError("You can not add your own email in cc");
    } else {
      setCCError(false);
    }
    const validEmails = val?.filter((email) =>
      isValidEmail(email.value.trim())
    );
    const filteredEmails = validEmails.filter(
      (email) => email.value !== user.email
    );
    const payload = {
      target: {
        name: "cc",
        value: filteredEmails?.length ? filteredEmails.map((c) => c.value) : [],
      },
    };
    handleInputChange(payload);
  };
  return (
    <div>
      <CreatableSelect
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: 0,
            boxShadow: "none",
            "&:hover": {
              borderColor: "#005f73"
            },
            border: state.isFocused ? "1px solid #005f73" : "1px solid #DADCE0", // Change border color when focused
            borderRadius: "4px",
            outline: state.isFocused ? "none" : "",
            fontFamily: "Muli light",
            fontSize: "text-skin-base",
            backgroundColor: "white",
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
              ? '#F4FDFF'
              : isSelected
                ? '#F4FDFF'
                : "#ffffff",
            zIndex: 1
          }),
          multiValue: (styles, { data }) => {
            return {
              ...styles,
              backgroundColor: "#d6f9ff",
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#182444",
            fontFamily: "Muli light"
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#9f9f9f",
            ':hover': {
              backgroundColor: "#d6f9ff",
              color: '#f98c76',
            },
          }),
          // other styles as needed
        }}
        isMulti
        value={
          form?.cc?.length
            ? form?.cc.map((c) => ({ label: c, value: c }))
            : null
        }
        options={emails}
        onChange={(val, actionData) => handleOnCHange(val, actionData)}
        onInputChange={debounce((val) => fetchEmails(val), 500)}
        placeholder="name@example.com"
        formatCreateLabel={(data) => {
          // console.log("data", data);
          if (emails.length === 0) {
            return `Add ${data}`;
          }
        }}
      />
    </div>
  );
};
// CcEmails.defaultProps = {
//   submitBtnClickedOnce: () => {},
//   form: {},
//   setError: () => {},
//   dependencyDropdownData: {},
// };
export default CcEmails;