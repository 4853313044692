import { useNavigate, useParams } from "react-router-dom";

function useCustomNavigate() {
  const { id } = useParams();

  const reactNavigate = useNavigate();

  return (pathname) => {
    if (typeof pathname === "string") {
      const path = `${pathname}`;
      reactNavigate(path);
      return;
    }

    const path = `${pathname?.pathname}`;

    reactNavigate({ ...pathname, pathname: path });
  };
}

export default useCustomNavigate;
