import Loading from "../../common/components/Loading";
import TicketHeading from "./TicketHeading";
import { AlertTriangle, CalendarDays, Clock } from "lucide-react";

function getDisplayTicketDetails(ticket = {}, reOpenTicket, isUpdating) {
  const { status, priority, created_at } = ticket;

  return [
    {
      value: (
        <div className="flex items-center">
          {status}
          {status === "completed" ? (
            <button
              className="px-2 py-1 rounded-md bg-primary text-white ml-4 flex space-x-2 items-center"
              onClick={reOpenTicket}
            >
              {isUpdating ? <Loading cls="!w-3 !h-3" /> : null}
              Reopen
            </button>
          ) : null}
        </div>
      ),
      Icon: Clock,
    },
    {
      value: priority,
      Icon: AlertTriangle,
    },
    {
      value: new Date(created_at).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }),
      Icon: CalendarDays,
    },
  ];
}

function TicketDetails({ ticket, reOpenTicket, isUpdating }) {
  const ticketDetails = getDisplayTicketDetails(
    ticket,
    reOpenTicket,
    isUpdating
  );

  return (
    <div className="w-full border md:mt-0 mt-5">
      <TicketHeading label={"Details"} />

      <div className="px-4 py-2 flex flex-col space-y-3 text-sm">
        {ticketDetails.map((t, i) => {
          const { Icon, value } = t;

          return (
            <div className="flex items-center space-x-2 text-skin-base font-mulilight text-sm" key={i}>
              <Icon size={18} />
              <div>{value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TicketDetails;
