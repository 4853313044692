import Input from "../../common/components/Input";
// import { Input } from "../../common/components/Input";
import SelectInput from "../../common/components/SelectInput";
import DependencyDropdown from "./DependencyDropdown";

function AdditionalFieldsForm({
  field,
  additionalFields,
  updateAdditionalFields,
  error,
}) {
  const { field_name, field_type, field_values, id, is_required } = field;

  const value = additionalFields[id];

  if (field_type === "input") {
    return (
      <>
        <Input
          label={field_name}
          required={is_required}
          onChange={(e) => updateAdditionalFields(id, e.target.value, field)}
          value={value}
        />
        {error[id] ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error[id]}</div> : null}
      </>
    );
  }

  if (field_type === "select") {
    const options = field_values?.map((f) => ({ label: f, value: f }));

    return (
      <>
        <SelectInput
          label={field_name}
          required={is_required}
          options={options}
          value={value}
          onChange={(e) => {
            updateAdditionalFields(id, e.target.value, field);
          }}
        />
        {error[id] ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error[id]}</div> : null}
      </>
    );
  }

  if (field_type === "dependency_dropdown") {
    return (
      <DependencyDropdown
        field={field}
        selectedValues={value}
        handleChange={(val) => {
          const value = Object.keys(val).length ? val : null;

          updateAdditionalFields(id, value, field);
        }}
        fromApprovalPage={is_required}
        error={error}
      />
    );
  }

  return "Unknown Type";
}

export default AdditionalFieldsForm;
