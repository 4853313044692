import React, { useEffect, useMemo, useState } from "react";
import ListTicketsTable from "../components/ListTicketsTable";
import Modal from "../../common/components/Modal";
import ViewTicket from "../components/ViewTicket";
import useApiData from "../../common/hooks/useApiData";
import { usePopup } from "../../common/contexts/PopupContext";
import useTicket from "../hooks/useTicket";
import Pagination from "../../common/components/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setQueryParams } from "../../common/helpers/commonHelpers";
import useCustomNavigate from "../../common/hooks/useCustomNavigate";

const childTickets = [
  {
    uuid: "0b4acfd9-991b-4c3a-8e13-50615b628827",
    parent_ticket_no: 424497,
    child_ticket_no: 219835,
    topic: "Option 1",
  },
];

function getDefaultFormValues(ticketCustomFields = []) {
  const obj = {};

  ticketCustomFields.forEach((c) => {
    const { customfield_id, customfield_values } = c;
    obj[customfield_id] = customfield_values;
  });

  return obj;
}

function TicketsContainer({ refreshData, setRefreshData }) {
  // const [search, setSearch] = useState("");
  const [updating, setUpdating] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [customFieldsForm, setCustomFieldsForm] = useState({});
  const [editCustomFields, setEditCustomFields] = useState(false);

  const [searchParams] = useSearchParams();
  const sort = searchParams.get("sort");
  const order = searchParams.get("order");
  const page = searchParams.get("page");
  const search = searchParams.get("search");
  const ticket_id = searchParams.get("ticket_id");
  const status = searchParams.get("status");

  const [isLargeScreen, setIsLargeScreen] = useState(false);
  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); // Assuming lg breakpoint is 1024px
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    if (refreshData) {
      const payload = { search, queryParams: {} };

      if (sort && order) {
        payload.queryParams = { ...payload.queryParams, sort, order };
      }

      if (status) {
        payload.queryParams = { ...payload.queryParams, status };
      }

      mutate(getTickets(payload, page));
      setRefreshData(false)
    }
  }, [refreshData])
  const filterParams = {
    sort,
    order,
    page,
    search,
    status,
  };

  const { toast } = usePopup();

  const navigate = useCustomNavigate();

  const {
    getTickets,
    getCommunityFields,
    getTicket,
    updateTicket,
    updateCustomFields,
    addComment,
    updateComment,
  } = useTicket();

  const { data, mutate, loading } = useApiData({
    onError: (error) => toast("Unable to get tickets", "error"),
  });

  const {
    data: ticketData,
    mutate: getTicketMutate,
    loading: isGettingTicket,
  } = useApiData({
    onSuccess: (res) => {
      const { ticketCustomFields } = res.data?.data || {};
      setCustomFieldsForm(getDefaultFormValues(ticketCustomFields));
      setInitialLoading(false);
    },
    onError: (error) => {
      toast("Unable to get ticket", "error");
      setInitialLoading(false);
    },
  });

  const { mutate: updateTicketMutate } = useApiData({
    onSuccess: () => {
      toast("Ticket updated successfully", "success");
      getTicketMutate(getTicket({}, ticket_id));
      setUpdating("");
    },
    onError: (error) => {
      toast("Unable to update ticket", "error");
      getTicketMutate(getTicket({}, ticket_id));
      setUpdating("");
    },
  });

  const { mutate: updateCustomFieldsMutate, loading: isUpdatingCustomFields } =
    useApiData({
      onSuccess: () => {
        toast("Custom fields updated successfully", "success");
        getTicketMutate(getTicket({}, ticket_id));
        setEditCustomFields(false);
      },
      onError: (error) => {
        toast("Unable to update ticket", "error");
        getTicketMutate(getTicket({}, ticket_id));
      },
    });

  const {
    data: communityFieldsData,
    mutate: getCommunityFieldsMutate,
    loading: communityFieldsLoading,
  } = useApiData({
    onError: (error) => toast(error?.response?.data?.message, "error"),
  });

  const { mutate: addCommentMutate, loading: isAddingComment } = useApiData({
    onSuccess: () => {
      toast("Comment added successfully", "success");
      getTicketMutate(getTicket({}, ticket_id));
    },
    onError: (error) => {
      toast("Unable to add comment", "error");
      getTicketMutate(getTicket({}, ticket_id));
    },
  });

  const { mutate: updateCommentMutate, loading: isUpdatingComment } =
    useApiData({
      onSuccess: () => {
        setCommentId("");
        toast("Comment updated successfully", "success");
        getTicketMutate(getTicket({}, ticket_id));
      },
      onError: (error) => {
        toast("Unable to update comment", "error");
        getTicketMutate(getTicket({}, ticket_id));
      },
    });

  useEffect(() => {
    getCommunityFieldsMutate(getCommunityFields());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!search || search?.length >= 3) {
      const payload = { search, queryParams: {} };

      if (sort && order) {
        payload.queryParams = { ...payload.queryParams, sort, order };
      }

      if (status) {
        payload.queryParams = { ...payload.queryParams, status };
      }

      mutate(getTickets(payload, page));
    }
  }, [search, sort, order, page, status]);

  useEffect(() => {
    if (ticket_id) {
      getTicketMutate(getTicket({}, ticket_id));
      setInitialLoading(true);
    }
  }, [ticket_id]);

  const tickets = data?.data || [];

  const additionalFields = useMemo(() => {
    return communityFieldsData?.data || [];
  }, [communityFieldsData]);


  const ticketDetails = ticketData?.data || {};

  const customFieldsProps = useMemo(() => {
    return {
      edit: editCustomFields,
      setEdit: setEditCustomFields,
      form: customFieldsForm,
      setForm: setCustomFieldsForm,
      ticketCustomFields: ticketDetails?.ticketCustomFields,
      customFields: additionalFields,
      updateCustomFields: (additionalFields) =>
        updateCustomFieldsMutate(
          updateCustomFields({ additionalFields }, ticket_id)
        ),
      isUpdatingCustomFields,
    };
  }, [
    editCustomFields,
    setEditCustomFields,
    additionalFields,
    customFieldsForm,
    isUpdatingCustomFields,
    ticket_id,
    ticketDetails?.ticketCustomFields,
    updateCustomFields,
    updateCustomFieldsMutate,
  ]);

  return (
    <div className="w-full max-w-[1440px] sm:px-[40px] lg:w-[65%] lg:px-[0px] px-[10px]">
      <ListTicketsTable
        openTicketDetails={(id) => {
          // setOpenTicket(id);
          // getTicketMutate(getTicket({}, id));
          // setInitialLoading(true);
          const params = { ...filterParams, ticket_id: id };
          if (isLargeScreen) {
            navigate({
              pathname: "/",
              search: setQueryParams(params),
            });
          }
          else {
            navigate({
              pathname: "/tickets",
              search: setQueryParams(params),
            });
          }
        }}
        tickets={tickets}
        additionalFields={additionalFields}
        communityFieldsLoading={communityFieldsLoading}
        onSearch={(e) => {
          const params = { ...filterParams, search: e.target.value };
          if (isLargeScreen) {
            navigate({
              pathname: "/",
              search: setQueryParams(params),
            });
          }
          else {
            navigate({
              pathname: "/tickets",
              search: setQueryParams(params),
            });
          }
        }}
        searchValue={search}
        onSort={(sortData = {}) => {
          const params = { ...filterParams, ...sortData };

          if (isLargeScreen) {
            navigate({
              pathname: "/",
              search: setQueryParams(params),
            });
          }
          else {
            navigate({
              pathname: "/tickets",
              search: setQueryParams(params),
            });
          }
        }}
        onClear={() => {
          navigate("/tickets");
        }}
        loading={loading}
        data={data}
        filters={{ status }}
        onFilter={(filter) => {
          const params = { ...filterParams, ...filter };

          if (isLargeScreen) {
            navigate({
              pathname: "/",
              search: setQueryParams(params),
            });
          }
          else {
            navigate({
              pathname: "/tickets",
              search: setQueryParams(params),
            });
          }
        }}
      />

      {tickets?.length ? (
        <Pagination
          data={{ meta: data }}
          handlePageChange={(page) => {
            const params = { ...filterParams, page };

            if (isLargeScreen) {
              navigate({
                pathname: "/",
                search: setQueryParams(params),
              });
            }
            else {
              navigate({
                pathname: "/tickets",
                search: setQueryParams(params),
              });
            }
          }}
        />
      ) : null}

      <Modal
        isOpen={ticket_id}
        onClose={() => {
          // setOpenTicket(false);
          // navigate({
          //   pathname: "/tickets",
          //   search: setQueryParams(filterParams),
          // });
          if (isLargeScreen) {
            navigate({
              pathname: "/",
              search: setQueryParams(filterParams),
            });
          }
          else {
            navigate({
              pathname: "/tickets",
              search: setQueryParams(filterParams),
            });
          }
        }}
        title=""
        contentCls="!max-h-[87vh]"
      >
        <ViewTicket
          ticket={ticketDetails}
          childTickets={childTickets}
          initialLoading={initialLoading}
          isGettingTicket={isGettingTicket}
          updateTicket={(update, updateType) => {
            setUpdating(updateType);
            updateTicketMutate(updateTicket(update, ticket_id));
          }}
          updating={updating}
          customFieldsProps={customFieldsProps}
          addComment={(payload) => {
            addCommentMutate(addComment(payload, ticket_id));
          }}
          isAddingComment={isAddingComment}
          updateComment={(payload) => {
            updateCommentMutate(
              updateComment({ ...payload, comment_id: commentId }, ticket_id)
            );
          }}
          isUpdatingComment={isUpdatingComment}
          setCommentId={setCommentId}
          commentId={commentId}
        />
      </Modal>
    </div>
  );
}

export default TicketsContainer;
