import axios from "axios";
import { getLocalStorage } from "./browserStorage";
import { getOrigin } from "./commonHelpers";

function extractPathComponent(url) {
  const match = url.match(/\/support\/([^\/]+)/);
  return match ? match[1] : null;
}

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_SUPPORT_PANEL_API_ENDPOINT,
});

apiInstance.interceptors.request.use(
  (config) => {
    const accessToken = getLocalStorage("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    const url =
      getOrigin() + "/support/" + extractPathComponent(window.location.href);

    config.headers.Requesturl = process.env.REACT_APP_SUPPORT_PANEL_URL;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error from api", error);
    const { pathname, origin } = window.location;

    const isTokenExpired = ["Token Expired", "Unauthenticated."].includes(
      error?.response?.data?.message
    );

    const parsedData = pathname.split("/");

    if (pathname !== "/login" && isTokenExpired) {
      window.location.href = origin + "/" + parsedData[1] + "/login";
      localStorage.clear();
    }

    return Promise.reject(error);
  }
);

export default apiInstance;
