import { Plus } from "lucide-react";
import useDropDown from "../hooks/useDropDown";

function FilterButton({
  open,
  setOpen,
  label,
  children,
  selected,
  pluralLabel,
  noScroll,
  childrenCls,
  dontCloseOnReactSelect,
  showPluralLabel,
  position,
}) {
  const { elementRef } = useDropDown({ setOpen, dontCloseOnReactSelect });

  return (
    <div className="relative flex items-center" ref={elementRef}>
      <button
        className="flex items-center px-4 py-2 border-dotted border-2 border-gray-400 rounded-md hover:bg-muted w-fit text-sm"
        onClick={() => setOpen((prev) => !prev)}
      >
        <Plus className="mr-2" size={16} />
        {label}
        <DisplaySelectedOption
          showPluralLabel={showPluralLabel}
          selected={selected}
          pluralLabel={pluralLabel}
        />
      </button>

      {open ? (
        <div
          className={`absolute z-[40] border top-12 ${position ? position : "left-0"
            } bg-white rounded-md ${noScroll ? "" : "max-h-[150px] overflow-y-auto"
            } ${childrenCls}`}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
}

export default FilterButton;

function DisplaySelectedOption({ showPluralLabel, selected, pluralLabel }) {
  const selectedCount = selected?.length;

  if (!selected?.length) {
    return;
  }

  const display =
    selected?.length > 2 || showPluralLabel
      ? [{ label: `${selectedCount} ${pluralLabel}` }]
      : selected;

  return display?.map((s, i) => {
    return (
      <div
        className="ml-3 pl-3 border-l border-gray-400 min-w-28 truncate font-semibold text-gray-500"
        key={i}
      >
        {s.label}
      </div>
    );
  });
}

export function SelectFilterOptionCheckbox({
  isChecked,
  handleCheckboxChange,
  label,
  id,
}) {
  return (
    <div className="flex items-center space-x-3 px-4 py-2 border-b hover:bg-muted">
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={id} className="w-20 truncate cursor-pointer">
        {label}
      </label>
    </div>
  );
}

export function SelectFilterOptionRadio({
  isChecked,
  handleChange,
  label,
  id,
}) {
  return (
    // <div>
      <div id={id} className={`items-center w-full py-2 ${isChecked && 'active'} hover:border-b-2 hover:border-primary border-b-2 border-[#F4FDFF]`} onClick={handleChange}>
        {/* <input type="radio" id={id} checked={isChecked} onChange={handleChange} /> */}
        <p className={`flex md:px-2 px-1 items-center justify-center truncate cursor-pointer text-sm text-skin-primary font-mulisemibold`}>
          {label}
        </p>
      </div>
    // </div>
  );
}
