function getKey(key) {
  const keyPrefix = "support-panel-";
  return keyPrefix + key;
}

export function setLocalStorage(key, value) {
  try {
    if (typeof value === "object") {
      value = JSON.stringify(value);
    }

    localStorage.setItem(getKey(key), value);
  } catch (error) {
    console.error("Error storing in localStorage:", error);
  }
}

export function getLocalStorage(key) {
  try {
    const storedValue = localStorage.getItem(getKey(key));
    return storedValue && isJsonString(storedValue)
      ? JSON.parse(storedValue)
      : storedValue;
  } catch (error) {
    console.error("Error retrieving from localStorage:", error);
    return null;
  }
}

export function removeLocalStorage(key) {
  try {
    localStorage.removeItem(getKey(key));
  } catch (error) {
    console.error("Error removing from localStorage:", error);
  }
}

function isJsonString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
