import { Check, X, Edit } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import Loading from "../../common/components/Loading";
import { isQuillHavingText } from "../../common/helpers/commonHelpers";


function getCls(edit, error) {
  return `flex item-center just text-skin-base hover:bg-[#F4FDFF] px-2 py-1 font-mulisemibold text-base outline-none w-full ${edit ? "border-[1px] border-primary rounded-md" : ""
    } ${error ? "border border-red-600" : ""}`;
}

function EditTopic(props) {
  const { updateTicket, isUpdating } = props;

  const [topic, setTopic] = useState(props.topic);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);

  const inputRef = useRef(null);

  function clearData() {
    setError(false);
    setEdit(false);
  }

  useEffect(() => {
    setTopic(props.topic);
  }, [props.topic]);

  if (isUpdating) {
    return <Loading />;
  }

  return (
    <div className="relative">
      {/* {edit ? (
        <input
          className={getCls(edit, error)}
          type="text"
          value={topic}
          onFocus={() => setEdit(true)}
          onBlur={() => {
            if (topic === props.topic) {
              setEdit(false);
            }
          }}
          onChange={(e) => setTopic(e.target.value)}
        />
      ) : (
        <div
          className={getCls(edit, error)}
          onClick={() => {
            setEdit(true);
            if (inputRef.current) {
              inputRef.current.onFocus();
            }
          }}
        >
          {topic}
        </div>
      )}
       */}
        <div
          onBlur={(t) => {
            // if (topic === props.topic) {
            //   setEdit(false);
            // }

            setTopic(t.currentTarget.innerHTML.replace(/&nbsp;/g, ""));
          }}
          contentEditable
          className={`${getCls(edit, error)}`}
          onFocus={() => setEdit(true)}
          ref={inputRef}
          cl

        >
          {topic}
          {
            !edit && (
              <span
                onClick={() => setEdit(true)}
                className="cursor-pointer text-sm pl-3 pt-[7px]" >
                <Edit size={15} color="#005f73"/>
              </span>
            )
          }
        </div>

      {/* <PencilIcon
        className="h-4 mt-1 p-0.5 mx-0.5 bg-skin-secondary rounded-md text-white cursor-pointer"
        width={20}
      /> */}

      {error ? <div className="my-2 text-red-600">{error}</div> : null}

      {edit ? (
        <div className="absolute top-12 right-0 flex items-center space-x-2">
          <button
            className="bg-white hover:bg-green-600 hover:text-white shadow-md p-2 border transistion duration-300 ease-in-out"
            onClick={() => {
              const topic = inputRef.current.innerHTML;

              if (!isQuillHavingText(topic)) {
                setError("Topic is required");
                return;
              }

              updateTicket(topic);
              clearData();
            }}
          >
            <Check size={16} />
          </button>
          <button
            className="bg-white hover:bg-red-600 hover:text-white shadow-md p-2 border transistion duration-300 ease-in-out"
            onClick={() => {
              setTopic(props.topic);
              clearData();
            }}
          >
            <X size={16} />
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default EditTopic;
