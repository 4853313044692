import { useState } from "react";
import FilterButton, {
  SelectFilterOptionRadio,
} from "../../common/components/FilterButton";

// function getRadioOptions(options) {
//   return [...options, { label: "All", value: "" }];
// }

export const statusOptions = [
  { label: "All", value: "" },
  { value: "open", label: "Open" },
  { value: "inprogress", label: "In Progress" },
  { value: "hold", label: "Hold" },
  { value: "completed", label: "Completed" },
];

export function StatusFilter({ sortTicket, selectedStatus }) {
  const [open, setOpen] = useState(false);

  const selected = statusOptions?.find((s) => s.value === selectedStatus);
  return (
    // <FilterButton
    //   label={"Status"}
    //   selected={selected ? [selected] : null}
    //   open={open}
    //   setOpen={setOpen}
    // >
    <>
      <div className="flex justify-start
       sm:overflow-x-auto">
        {statusOptions?.map((status, i) => {
          return (
            <SelectFilterOptionRadio
              key={i}
              id={status.value || "none"}
              label={status.label}
              handleChange={(e) => {
                sortTicket({
                  status: status?.value,
                });
              }}
              isChecked={selectedStatus === status.value}
            />
          );
        })}
      </div>
    </>
    // </FilterButton>
  );
}
