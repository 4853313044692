import { ArrowDownToLine, Trash2 } from "lucide-react";
import ToolTip from "../../common/components/ToolTip";

function FileUpload({
  files,
  handleFileUpload,
  onRemove,
  onView,
  onDownload,
  disabled,
  btnLabel = "Choose Files",
  label,
  labelStyles,
  id,
  hideNoAttachments,
}) {
  return (
    <div>
      {label ? (
        <div className={`py-2 mb-4 border-b border-gray-300 ${labelStyles}`}>
          {label}
        </div>
      ) : null}

      {files?.length ? (
        <ShowFiles
          files={files}
          onRemove={onRemove}
          onView={onView}
          onDownload={onDownload}
          disabled={disabled}
        />
      ) : (
        <div className={`${labelStyles} py-2`}>
          {hideNoAttachments ? null : "No Attachments"}
        </div>
      )}

      {disabled ? null : (
        <div className="mt-2">
          <input
            type="file"
            id={id}
            multiple
            className="hidden"
            onChange={handleFileUpload}
          />
          <label
            htmlFor={id}
            className="cursor-pointer border border-skin-line hover:border-primary text-skin-base font-mulilight rounded px-[10px] py-[5px] text-sm hover:bg-[#F4FDFF]"
          >
            {btnLabel}
          </label>
        </div>
      )}
    </div>
  );
}

export default FileUpload;

function ShowFiles({ files = [], onRemove, onView, onDownload, disabled }) {
  return (
    <div className="mb-6 mt-2 grid grid-cols-2 gap-4 w-fit text-sm">
      {files.map((f, i) => {
        const name = f.fileName || f.filename || `Attachment - ${i + 1}`;

        return (
          <div className="flex items-center space-x-2" key={i}>
            <div className="text-primary hover:text-primary bg-[#F4FDFF] border border-primary rounded-md px-4 py-1.5 w-fit flex items-center">
              <ToolTip tip={name}>
                <button
                  className={`w-24 truncate ${onView ? "mr-6 border-primary" : ""
                    }`}
                  onClick={() => {
                    if (onView) {
                      onView(f.link);
                    }
                  }}
                >
                  {name}
                </button>
              </ToolTip>

              {onDownload ? (
                <a href={f?.link} download target="__blank">
                  <ArrowDownToLine size={16} className="hover:text-black" />
                </a>
              ) : null}
            </div>

            {disabled ? null : (
              <Trash2
                size={18}
                className="text-skin-textred hover:opacity-80 cursor-pointer"
                onClick={() => onRemove(i, f)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
