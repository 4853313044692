function Table({ containerCls = "", data = [], columns }) {

  return (
    <div className={`overflow-x-auto text-gray-700 border border-[#43C4DB] rounded-[5px] mb-6`}>
      <table className={`table-auto w-full text-left  ${containerCls}`} border={"2"}>
        <thead>
          <tr className="">
            {columns.map((col, i) => {
              return (
                <th key={i} className="text-skin-base text-base font-muli font-normal bg-muted py-1.5 px-3">
                  {col.name}
                </th>
              );
            })}
          </tr>
        </thead>
        {/* ${Comp == "Very High"
                        ? "text-green-400"
                        : Comp == "High"
                          ? "text-[#CAD883]"
                          : Comp == "Medium"
                            ? "text-[#FFC233]"
                            : Comp == "Low"
                              ? "text-[#F98C76]"
                              : ""
                      } */}
        <tbody>
          {data.map((d, i) => {
            return (
              <tr key={i} className="bg-white hover:bg-[#F4FDFF]">
                {columns.map((col, j) => {
                  const Comp = col.renderComp(d, i);
                  return (
                    <td key={j} className="border-t border-gray-300 px-3 py-[10px]" >

                      <div
                        className={` 
                        ${j == 0 ? "font-mulisemibold text-primary" :
                            (j == 1 || j == 3) ? "font-mulilight text-skin-base " :
                              (j == 2 && Comp == "Very High") ? "py-1 text-sm rounded-md text-start pl-2 font-mulilight text-skin-base bg-[#d4faff] " :
                                (j == 2 && Comp == "High") ? "py-1 text-sm rounded-md text-start pl-2 font-mulilight text-skin-base bg-[#ead2ac]" :
                                  (j == 2 && Comp == "Medium") ? "py-1 text-sm rounded-md text-start pl-2 font-mulilight text-skin-base bg-[#e5eff1]" :
                                    (j == 2 && Comp == "Low") ? "py-1 text-sm rounded-md text-start pl-2 font-mulilight text-skin-base bg-[#ead2ac40]" :
                                      ""}`}
                      >
                        {Comp}
                      </div>
                    </td>

                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div >
  );
}

export default Table;
