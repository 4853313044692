import { getLocalStorage } from "./browserStorage";

export function isHavingError(error = {}) {
  return Object.values(error).some((val) => val);
}

export function validateRequiredInput(value, name = "This field") {
  if (!value?.trim()) {
    return `${name} is required`;
  }
}

export function udpateState(setState, update) {
  setState((prev) => ({ ...prev, ...update }));
}

export const priorities = [
  {
    label: "Very High",
    value: "p1",
  },
  {
    label: "High",
    value: "p2",
  },
  {
    label: "Medium",
    value: "p3",
  },
  {
    label: "Low",
    value: "p4",
  },
];

function levelValidation(field_name, formValue, choices) {
  if (!formValue?.level1) {
    return;
  }
  let prevLevelChoices = choices;
  const error = {};
  Object.entries(field_name).forEach(([key, value], index) => {
    const levelNo = index + 1;
    const levelKey = `level${levelNo}`;
    const isHavingChoices = prevLevelChoices?.some((l) => l.label);
    if (!formValue[levelKey] && isHavingChoices) {
      error[levelKey] = "This field is required";
    }
    const nextChoices = prevLevelChoices?.find(
      (l) => l.label === formValue[levelKey]
    );
    prevLevelChoices = nextChoices?.choices;
  });
  return error;
}
export function validateAdditionalFields(
  additionalFields = {},
  dependency_dropdown_data
) {
  const error = {};
  let havingError = false;
  dependency_dropdown_data.forEach((field) => {
    if (field.is_required === 1) {
      if (
        additionalFields[field.id] === undefined ||
        additionalFields[field.id] === null ||
        !additionalFields[field.id]
      ) {
        error[field.id] = { [field.id]: "This field is required" };
        havingError = true;
      }
    }
  });
  Object.entries(additionalFields).forEach(([key, value]) => {
    if (value?.level1) {
      // if value?.level1 means it is depenedency dropdown
      const { field_values = {}, field_name } =
        dependency_dropdown_data.find((d) => d.id.toString() === key) || {};
      const { choices = [] } = field_values;
      if (field_name) {
        const validatedData = levelValidation(field_name, value, choices);
        if (Object.keys(validatedData)?.length) {
          error[key] = validatedData;
          havingError = true;
        }
      }
    }
  });
  return { error, havingError };
}

const marginDistance = 20;
export function getLevelFieldsMargin(index) {
  // const prev = index - 1;

  // const prevMargin = index <= 1 ? 0 : prev;

  // const currentMargin = index <= 1 ? 0 : index;

  // const margin = index <= 1 ? 0 : prevMargin + currentMargin + marginDistance;

  // return margin;
  if (index <= 1) {
    return 0;
  }
  return (index - 1) * marginDistance;
}

export const handleFileUpload = async (
  e,
  setFormLoading,
  updateAttachments
) => {
  setFormLoading(true);

  const files = Array.from(e.target.files);
  var attachmentFiles = [];
  const maxSizeInBytes = 3145728; // 3MB

  const attachmentPromises = files.map((attachment) => {
    return new Promise((resolve, reject) => {
      if (attachment.size > maxSizeInBytes) {
        reject(new Error("File size exceeds the limit (3MB)"));
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target.result;
        const base64Data = fileData.split(",")[1];
        const mimeType = fileData.match(/^data:(.*?);/)[1];
        const fileName = attachment.name;
        const fileExtension = attachment.name.split(".").pop().toLowerCase();
        attachmentFiles.push({
          base64Data,
          fileName,
          fileExtension,
          mimeType,
        });
        resolve();
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(attachment);
    });
  });

  try {
    await Promise.all(attachmentPromises);
    updateAttachments(attachmentFiles);
    setFormLoading(false);
  } catch (error) {
    alert(error.message);
    setFormLoading(false);
  }
};

export function getAgoFromDate(date) {
  if (!date) {
    return {};
  }

  const createdAt = new Date(date);

  // Current time
  const now = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = now - createdAt;

  // Convert milliseconds to different units (hours, days, months)
  const millisecondsInHour = 60 * 60 * 1000;
  const millisecondsInDay = 24 * millisecondsInHour;
  const millisecondsInMonth = 30.44 * millisecondsInDay; // Approximate value for a month

  const minutesAgo = Math.floor(timeDifference / (60 * 1000));
  const hoursAgo = Math.floor(timeDifference / millisecondsInHour);
  const daysAgo = Math.floor(timeDifference / millisecondsInDay);
  const monthsAgo = Math.floor(timeDifference / millisecondsInMonth);

  let display = "";

  if (minutesAgo) {
    display = `${minutesAgo}m ago`;
  }

  if (hoursAgo) {
    display = `${hoursAgo}h ago`;
  }

  if (daysAgo) {
    display = `${daysAgo}d ago`;
  }

  if (monthsAgo) {
    display = `${monthsAgo}months ago`;
  }

  return { hoursAgo, daysAgo, monthsAgo, display };
}

export function checkAuth() {
  const accessToken = getLocalStorage("accessToken");
  return accessToken;
}

export function getOrigin() {
  return process.env.REACT_APP_DOMAIN_URL;
}

export function setQueryParams(params) {
  const newParams = {};

  Object.entries(params).forEach(([key, value], i) => {
    if (value) {
      newParams[key] = value;
    }
  });

  return "?" + new URLSearchParams(newParams).toString();
}

export function isQuillHavingText(text) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = text || "";
  const textContent = tempElement.textContent;

  return textContent.trim().length > 0;
}

export const basePath = "";

export const isValidEmail = (value) => {
  // Regular expression for validating an Email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};
