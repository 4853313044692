import Button from "../../common/components/Button";
import PrimaryButton from "../../common/components/PrimaryButton";
import Textarea from "../../common/components/Textarea";
import FileUpload from "./FileUpload";

function CommentForm({
  form,
  error,
  onChangeTextArea,
  handleFileUpload,
  onRemove,
  loading,
  onSubmit,
  onCancel,
  submitLabel = "Comment",
}) {
  return (
    <div className="w-full">
      <Textarea
        placeholder="Leave a comment"
        inputCls="min-h-[100px]"
        name="comment"
        onChange={onChangeTextArea}
        value={form.comment}
        error={error.comment}

      />

      <div className="md:flex justify-between items-center  w-full">
        <FileUpload
          files={form?.attachments}
          handleFileUpload={handleFileUpload}
          onRemove={onRemove}
          id="ticketComment"
          hideNoAttachments={true}
        />

        <div className="flex justify-center items-center space-x-2 gap-4 pt-[22px]">
          <PrimaryButton
            btnCls="!w-fit px-7 text-sm font-mulisemibold md:py-2 py-[6px]"
            onClick={onSubmit}
            loading={loading}
            marginTop="!mt-2"
          >
            {submitLabel}
          </PrimaryButton>

          {onCancel ? (
            <Button
              className="px-4rounded-md text-gray-600 font-mulisemibold hover:text-skin-textred"
              onClick={onCancel}
            >
              Cancel
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CommentForm;
