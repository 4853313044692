import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import Alert from "../components/Alert";

const DEFAULT_MSG = {
  success: "success",
  error: "Something went wrong",
  warning: "Warning!",
};

const PopupContext = createContext();

export const usePopup = () => {
  return useContext(PopupContext);
};

function getDefaultPopupState() {
  return {
    type: "",
    message: "",
    show: false,
  };
}

export const PopupProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState(getDefaultPopupState());

  const timerId = useRef();

  const clearPopup = useCallback(() => {
    clearTimeout(timerId.current);
    setShowPopup((prev) => ({ ...prev, show: false }));
  }, []);

  const toast = useCallback(
    (msg, type, timeout = 5000) => {
      clearTimeout(timerId.current);

      let message = DEFAULT_MSG[type];

      if (message && typeof message === "string") {
        message = msg;
      }

      setShowPopup({ type, message, show: true });

      timerId.current = setTimeout(() => {
        clearPopup();
      }, timeout);
    },
    [clearPopup]
  );

  return (
    <PopupContext.Provider value={{ toast }}>
      <Alert showPopup={showPopup} onClose={clearPopup} />

      {children}
    </PopupContext.Provider>
  );
};
