import { X } from "lucide-react";
import React, { useRef } from "react";

const Modal = ({ isOpen, onClose, children, title, contentCls = "" }) => {
  const modalRef = useRef();

  const handleClose = () => {
    onClose();
  };

  //   if (!isOpen) return null;

  return (
    <div
      className={`modal ${
        isOpen ? "modal--visible" : "modal--invisible"
      } flex items-center justify-center z-50`}
    >
      <div
        className={`modal ${
          isOpen ? "modal--visible" : "modal--invisible"
        }  bg-black opacity-50`}
        onClick={handleClose}
      ></div>
      <div
        ref={modalRef}
        className={`bg-white w-full max-w-[1100px] max-h-[70vh] overflow-y-auto p-4 rounded relative text-gray-700 m-4 ${contentCls}`}
      >
        <div className="flex justify-between items-center">
          <div className="text-lg font-semibold">{title}</div>

          <button onClick={handleClose}>
            <X size={20} />
          </button>
        </div>

        {children}
      </div>
    </div>
  );
};

export default Modal;
