import { useState } from "react";
import Input from "../../common/components/Input";
// import { Input } from "../../common/components/Input";
import PasswordInput from "../../common/components/PasswordInput";
import {
  validateEmail,
  validatePassword,
  validateUserName,
} from "../helpers/authHelpers";
import PrimaryButton from "../../common/components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import apiInstance from "../../common/helpers/apiInstance";
import useApiData from "../../common/hooks/useApiData";
import { usePopup } from "../../common/contexts/PopupContext";
import { useAuth } from "../../common/contexts/AuthContext";
import { setLocalStorage } from "../../common/helpers/browserStorage";
import useCustomNavigate from "../../common/hooks/useCustomNavigate";

function useSignUp() {
  return {
    signUp: (payload) => {
      return () => apiInstance.post("/auth/register", payload);
    },
  };
}

function udpateState(setState, update) {
  setState((prev) => ({ ...prev, ...update }));
}

function validateFormData(form) {
  const { username, email, password } = form;

  const usernameError = validateUserName(username);
  const emailError = validateEmail(email);
  const passwordError = validatePassword(password);

  return {
    username: usernameError,
    email: emailError,
    password: passwordError,
    havingError: usernameError || emailError || passwordError,
  };
}

function hasFormSubmit(
  e,
  form,
  error,
  setError,
  setSubmitBtnClickedOnce,
  signUp
) {
  e.preventDefault();

  setSubmitBtnClickedOnce(true);

  const errorData = validateFormData(form);

  setError(errorData);

  if (errorData.havingError) {
    return;
  }

  signUp();
}

function handleInputChange(e, submitBtnClickedOnce, form, setForm, setError) {
  const { value, name } = e.target;

  const payload = { [name]: value };

  udpateState(setForm, payload);

  if (submitBtnClickedOnce) {
    const errorData = validateFormData({ ...form, ...payload });

    setError(errorData);
  }
}

function getDefaultState() {
  return {
    username: "",
    email: "",
    password: "",
  };
}

function SignUpForm() {
  const [form, setForm] = useState(getDefaultState());
  const [error, setError] = useState(getDefaultState());
  const [submitBtnClickedOnce, setSubmitBtnClickedOnce] = useState(false);

  const navigate = useCustomNavigate();

  const { setUser, setAccessToken } = useAuth();

  const { toast } = usePopup();

  const { signUp } = useSignUp();

  const { mutate, loading } = useApiData({
    onSuccess: (res) => {
      const { data = {} } = res;
      const { status, message, user = {}, authorisation = {} } = data;

      toast(message, status);

      if (status === "success") {
        const { token } = authorisation;
        setUser(user);
        setAccessToken(token);
        setLocalStorage("accessToken", token);
        navigate("/");
      }
    },
    onError: (error) => {
      toast(error?.response?.data?.message, "error");
    },
  });

  return (
    <div className="lg:w-[600px] md:w-full sm:w-full lg:px-[0px] md:px-[40px] sm:[10px] px-[10px] mx-auto mt-28">
      <div className="text-gray-700 bg-white border p-8 rounded-lg shadow-simpleshadow">
        <div className="font-mulisemibold text-[20px] text-skin-base">Sign up for support portal</div>
        <div className="my-2 flex justify-start">

          <p className="text-base font-mulisemibold"> Already a user?</p>
          <button
            className="text-primary hover:text-black ml-1.5 font-semibold"
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </div>

        <form
          noValidate
          onSubmit={(e) =>
            hasFormSubmit(e, form, error, setError, setSubmitBtnClickedOnce, () =>
              mutate(signUp(form))
            )
          }
          className="flex flex-col space-y-5 mt-6"
        >
          <div>
            <Input
              inputCls="text-skin-base font-mulilight text-base focus:border-primary focus:border-[1px]"
              labelCls="text-skin-base font-mulisemibold"
              label="User Name"
              name="username"
              required={true}
              placeholder="User Name"
              onChange={(e) => {
                handleInputChange(e, submitBtnClickedOnce, form, setForm, setError);
              }}
              value={form.username}
              error={error?.username}
            />
            {error?.username ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error?.username}</div> : null}
          </div>
          <div>
            <Input
              inputCls="text-skin-base font-mulilight text-base focus:border-primary focus:border-[1px]"
              labelCls="text-skin-base font-mulisemibold"
              label="Your e-mail address"
              name="email"
              required={true}
              placeholder={"name@example.com"}
              onChange={(e) => {
                handleInputChange(e, submitBtnClickedOnce, form, setForm, setError);
              }}
              value={form.email}
              error={error?.email}
            />
            {error?.email ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error?.email}</div> : null}
          </div>
          <div>
            <PasswordInput
              inputCls="text-skin-base font-mulilight text-base focus:border-primary focus:border-[1px]"
              labelCls="text-skin-base font-mulisemibold"
              label="Password"
              name="password"
              required={true}
              placeholder="password"
              onChange={(e) => {
                handleInputChange(e, submitBtnClickedOnce, form, setForm, setError);
              }}
              value={form.password}
              error={error?.password}
            />
            {error?.password ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error?.password}</div> : null}
          </div>
          <PrimaryButton type="submit" loading={loading}>
            Register
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
}

export default SignUpForm;
