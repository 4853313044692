import { useState } from "react";
import Button from "../../common/components/Button";
import Input from "../../common/components/Input";
// import { Input } from "../../common/components/Input";
import PasswordInput from "../../common/components/PasswordInput";
import PrimaryButton from "../../common/components/PrimaryButton";
import { validateEmail, validatePassword } from "../helpers/authHelpers";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../common/contexts/AuthContext";
import { usePopup } from "../../common/contexts/PopupContext";
import apiInstance from "../../common/helpers/apiInstance";
import useApiData from "../../common/hooks/useApiData";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../common/helpers/browserStorage";
import useCustomNavigate from "../../common/hooks/useCustomNavigate";
import { useApp } from "../../common/contexts/AppContext";

function useLogin() {
  return {
    login: (payload) => {
      return () => apiInstance.post("/auth/login", payload);
    },
  };
}

function udpateState(setState, update) {
  setState((prev) => ({ ...prev, ...update }));
}

function validateFormData(form) {
  const { email, password } = form;

  const emailError = validateEmail(email);
  const passwordError = validatePassword(password);

  return {
    email: emailError,
    password: passwordError,
    havingError: emailError || passwordError,
  };
}

function hasFormSubmit(
  e,
  form,
  error,
  setError,
  setSubmitBtnClickedOnce,
  login
) {
  e.preventDefault();

  setSubmitBtnClickedOnce(true);

  const errorData = validateFormData(form);

  setError(errorData);

  if (errorData.havingError) {
    return;
  }

  // const url = getOrigin() + "/support/" + baseName;
  login();
}

function handleInputChange(e, submitBtnClickedOnce, form, setForm, setError) {
  const { value, name } = e.target;

  const payload = { [name]: value };

  udpateState(setForm, payload);

  if (submitBtnClickedOnce) {
    const errorData = validateFormData({ ...form, ...payload });

    setError(errorData);
  }
}

function getDefaultState() {
  return {
    email: "",
    password: "",
  };
}

function LoginForm() {
  const [form, setForm] = useState(getDefaultState());
  const [error, setError] = useState(getDefaultState());
  const [submitBtnClickedOnce, setSubmitBtnClickedOnce] = useState(false);

  const navigate = useCustomNavigate();

  const { setUser, setAccessToken } = useAuth();

  const { toast } = usePopup();

  const { login } = useLogin();

  const { baseName } = useApp();

  console.log("baseName", baseName);

  const { mutate, loading } = useApiData({
    onSuccess: (res) => {
      const { data = {} } = res;
      const { status, message, user = {}, authorisation = {} } = data;

      toast(message, status);

      if (status === "success") {
        const { token } = authorisation;
        setUser(user);
        setAccessToken(token);
        setLocalStorage("accessToken", token);

        const requestedPath = getLocalStorage("requested-path");

        if (requestedPath) {
          window.location.href = requestedPath;
          removeLocalStorage("requested-path");
        } else {
          navigate("/");
        }
      }
    },
    onError: (error) => {
      toast(error?.response?.data?.message, "error");
    },
  });

  return (
    <div className="lg:w-[600px] md:w-full sm:w-full lg:px-[0px] md:px-[40px] sm:[10px] px-[10px] mx-auto mt-28">
      <div className="text-gray-700 bg-white border p-8 rounded-lg shadow-simpleshadow">
        <div className="font-semibold md:text-[20px] text-[20px] font-mulisemibold text-skin-base">Log in to support portal</div>

        <div className="my-2 md:flex grid justify-start">
          <p className="text-base font-mulisemibold">Are you a new user?</p>
          <button
            className="text-primary text-start font-mulilight md:ml-1.5 ml-0"
            onClick={() => navigate("/sign-up")}
          >
            Sign up with us
          </button>
        </div>

        <form
          noValidate
          onSubmit={(e) =>
            hasFormSubmit(e, form, error, setError, setSubmitBtnClickedOnce, () =>
              mutate(login(form))
            )
          }
          className="flex flex-col space-y-5 mt-6"
        >
          <div>
            <Input
              labelCls="text-skin-base font-mulisemibold"
              label="Your e-mail address"
              name="email"
              inputCls="text-skin-base font-mulilight text-base"
              required={true}
              placeholder={"name@example.com"}
              onChange={(e) => {
                handleInputChange(e, submitBtnClickedOnce, form, setForm, setError);
              }}
              value={form.email}
              error={error?.email}
            />

            {error?.email ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error?.email}</div> : null}
          </div>

          <div>
            <PasswordInput
              inputCls="text-skin-base font-mulilight text-base focus:border-primary"
              label="Password"
              labelCls="text-skin-base font-mulisemibold"
              name="password"
              required={true}
              placeholder="password"
              onChange={(e) => {
                handleInputChange(e, submitBtnClickedOnce, form, setForm, setError);
              }}
              value={form.password}
              error={error?.password}
            />
            {error?.password ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error?.password}</div> : null}
          </div>

          {/* <Button className="text-primary hover:text-black text-sm text-right">
          Forgot password?
        </Button> */}

          <PrimaryButton btnCls="text-sm font-mulisemibold" type="submit" loading={loading}>
            Login
          </PrimaryButton>

          {/* <div className="text-center text-gray-500">or login using</div>

        <Button className="bg-blue-600 hover:bg-blue-700 rounded-md flex items-center space-x-4 text-white w-fit mx-auto pr-10">
          <img
            src="/icons/google.svg"
            alt="google"
            className="w-8 bg-white m-0.5 rounded-l-md"
          />

          <div>Continue with Google</div>
        </Button> */}
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
