// function Input({
//   label,
//   labelCls = "",
//   inputCls = "",
//   error,
//   value = "",
//   ...inputProps
// }) {
//   const { required } = inputProps;

//   return (
//     <div>
//       <div>
//         {label ? (
//           <div
//             className={`my-1 font-mulisemibold text-base text-skin-base ${required ? "required-input" : ""}  ${labelCls}`}
//           >
//             {label}
//           </div>
//         ) : null}

//         <input
//           type="text"
//           className={`border outline-none focus:ring-primary ${
//             error ? "border-red-400" : "border-gray-300"
//           } rounded-md w-full px-2 py-2 ${inputCls}`}
//           {...inputProps}
//           value={value}
//           required={false}
//         />
//       </div>

//       {error ? <div className="text-red-600 pt-2 text-sm">{error}</div> : null}
//     </div>
//   );
// }

// export default Input;

import { forwardRef, useEffect, useState } from "react";
import { Input as InputMT } from "@material-tailwind/react";

const Input = forwardRef(
  (
    {
      type = "text",
      placeholder = "",
      className = "peer-focus:text-skin-base peer-focus:before:border-primary before:border-t-primary after:border-t-primary  peer-focus:after:border-primary text-skin-base",
      overwriteStyle = {},
      disabled = false,
      value,
      ...props
    },
    ref
  ) => {
    // const { field, fieldState } = useController(props);
    return (
      <>
        <InputMT
          style={overwriteStyle}
          className={`${type === "textarea" ? "h-[100px] py-1" : "h-11 py-3"
            } text-base  text-skin-base font-muli placeholder-shown:border-skin-line placeholder-shown:border-t-skin-line border-skin-line focus:border-skin-line focus:ring-0 floating-input`}
          labelProps={{
            className: `peer-placeholder-shown:font-muli peer-focus:leading-3 peer-focus:text-skin-base text-[14px] peer-focus:text-sm peer-focus:font-mulisemibold peer-focus:text-skin-base peer-placeholder-shown:text-skin-line peer-focus:before:!border-blue-500 before:border-t after:border-t peer-focus:after:!border-blue-500 font-mulisemibold text-skin-base peer-placeholder-shown:text-base peer-placeholder-shown:leading-[3.45]`,
          }}
          label={props.label || "Field Label"}
          ref={ref}
          type={type}
          color="blue"
          required={false}
          disabled={disabled}
          // readOnly
          value={value}
          // {...field}
          {...props}
        />
        {/* {error ? <div className="mt-1 text-xs text-skin-textred">{error}</div> : null} */}
      </>
    );
  }
);
Input.displayName = "Input";

// Input.defaultProps = {
//     type: "text",
//     placeholder: ""
// }
export default Input;

// export const Input = ({
//   label = "Enter here",
//   type = "text",
//   name = "field",

//   className = "peer-focus:text-skin-base peer-focus:before:border-primary before:border-t-primary after:border-t-primary  peer-focus:after:border-primary text-skin-base",
//   placeholder = " ",
//   defaultValue = "",
//   children,
//   height = "",
//   overwriteStyle = {},
//   ...rest
// }) => {
//   return (
//     <div className={`${type === "textarea" ? "h-[100px]" : ""} relative z-0 w-full`}>
//       <InputMT
//         style={overwriteStyle}
//         className={`${
//           type === "textarea" ? "h-[100px] py-1" : "h-11 py-3"
//         } text-base  text-skin-base font-muli placeholder-shown:border-skin-line placeholder-shown:border-t-skin-line border-skin-line focus:border-skin-line focus:ring-0 floating-input`}
//         label={label || "Field Label"}
//         type={type}
//         name={name}
//         readOnly
//         onWheel={() => document.activeElement.blur()}
//         onClick={() => console.warn("davereyoli")}
//         labelProps={{
//           className: `peer-placeholder-shown:font-muli peer-focus:leading-3 peer-focus:text-skin-base text-[14px] peer-focus:text-sm peer-focus:font-mulisemibold peer-focus:text-skin-base peer-placeholder-shown:text-skin-line peer-focus:before:border-skin-line before:border-t-line after:border-t-line peer-focus:after:border-skin-line font-mulisemibold text-skin-base peer-placeholder-shown:text-base peer-placeholder-shown:leading-[3.45]`,
//         }}
//         defaultValue={defaultValue}
//         {...rest}
//       />
//       {children}
//     </div>
//   );
// };
