// import { useMemo } from "react";
// import SelectInput from "../../common/components/SelectInput";
// import { CornerDownRight } from "lucide-react";

// function getChoices(choices = []) {
//   const options = [];

//   choices.forEach((c) => {
//     if (c.label === null || c.label === "") {
//       return;
//     }

//     options.push({
//       label: c.label,
//       value: c.label,
//     });
//   });

//   return options;
// }

// function DependencyDropdown({
//   field,
//   selectedValues = {},
//   handleChange,
//   fromApprovalPage,
//   error,
// }) {
//   const { field_name, field_values } = field;
//   const { choices = [] } = field_values;

//   const level1Data = useMemo(
//     () => selectedValues.level1,
//     [selectedValues.level1]
//   );

//   const level2Data = useMemo(
//     () => selectedValues.level2,
//     [selectedValues.level2]
//   );

//   const level3Data = useMemo(
//     () => selectedValues.level3,
//     [selectedValues.level3]
//   );

//   const level1Options = useMemo(() => {
//     return getChoices(choices);
//   }, [choices]);

//   const level2Options = useMemo(() => {
//     return getChoices(choices.find((c) => c.label === level1Data)?.choices);
//   }, [choices, level1Data]);

//   const level3Options = useMemo(() => {
//     const level2Choices =
//       choices.find((c) => c.label === level1Data)?.choices || [];

//     return getChoices(
//       level2Choices.find((c) => c.label === level2Data)?.choices
//     );
//   }, [choices, level1Data, level2Data]);

//   return (
//     <div className="">
//       {level1Options?.length ? (
//         <SelectData
//           label={field_name.level1}
//           options={level1Options}
//           onChange={(value) => {
//             const val = value;

//             if (!val && val !== 0) {
//               handleChange({});
//               return;
//             }

//             handleChange({ level1: val });
//           }}
//           value={level1Data}
//           customCls="ml-2"
//           level={1}
//           fromApprovalPage={fromApprovalPage}
//           error={error.level1}
//         />
//       ) : null}

//       {level1Data && level2Options?.length ? (
//         <div className="flex items-center space-x-2">
//           <CornerDownRight color="gray" className="w-8 ml-2" />

//           <SelectData
//             label={field_name.level2}
//             options={level2Options}
//             onChange={(value) => {
//               const val = value;

//               if (!val && val !== 0) {
//                 const prevData = selectedValues;

//                 delete prevData.level3;

//                 delete prevData.level2;

//                 handleChange(prevData);

//                 return;
//               }

//               const prevData = selectedValues;

//               delete prevData.level3;

//               prevData.level2 = val;

//               handleChange(prevData);
//             }}
//             value={level2Data}
//             level={2}
//             fromApprovalPage={fromApprovalPage}
//             error={error.level2}
//           />
//         </div>
//       ) : null}

//       {level1Data && level2Data && level3Options?.length ? (
//         <div className="flex items-center space-x-2">
//           <CornerDownRight color="gray" className="w-8 ml-12" />
//           <SelectData
//             label={field_name.level3}
//             options={level3Options}
//             onChange={(value) => {
//               const val = value;

//               if (!val && val !== 0) {
//                 const prevData = selectedValues;

//                 delete prevData.level3;

//                 handleChange(prevData);

//                 return;
//               }

//               const prevData = selectedValues;

//               prevData.level3 = val;

//               handleChange(prevData);
//             }}
//             value={level3Data}
//             level={3}
//             fromApprovalPage={fromApprovalPage}
//             error={error.level3}
//           />
//         </div>
//       ) : null}
//     </div>
//   );
// }

// export default DependencyDropdown;

// function SelectData({
//   options = [],
//   value = { label: "Select", value: "" },
//   defaultValue,
//   onChange,
//   label,
//   customCls = "w-full",
//   level,
//   fromApprovalPage,
//   error,
// }) {
//   const isRequired = level !== 1;

//   return (
//     <div className={customCls + " mt-2"}>
//       <SelectInput
//         options={options}
//         value={value}
//         onChange={(e) => onChange(e.target.value)}
//         placeholder="select"
//         required={isRequired}
//         label={label}
//         error={error}
//       />
//     </div>
//   );
// }


import { useEffect, useMemo, useState } from "react";
import SelectInput from "../../common/components/SelectInput";
import { CornerDownRight } from "lucide-react";
import { getLevelFieldsMargin } from "../../common/helpers/commonHelpers";
function getChoices(choices = []) {
  return choices
    .filter((c) => c.label !== null && c.label !== "")
    .map((c) => ({
      label: c.label,
      value: c.label,
    }));
}
function checkLabelLevel(data, currentLevel = 0) {
  if (data.label === null) {
    return currentLevel - 1;
  }
  let maxLevel = currentLevel;
  if (data.choices) {
    for (const choice of data.choices) {
      maxLevel = Math.max(maxLevel, checkLabelLevel(choice, currentLevel + 1));
    }
  }
  return maxLevel;
}
function DependencyDropdown({
  field,
  selectedValues = {},
  handleChange,
  fromApprovalPage,
  error,
}) {
  const { field_name, field_values, id } = field;
  const { choices = [] } = field_values;
  const [num, setNum] = useState(1);
  useEffect(() => {
    const maxLevelOptions = checkLabelLevel(field_values);
    setNum(maxLevelOptions);
  }, [field_values]);
  const levelsData = useMemo(() => {
    const levels = {};
    for (let i = 1; i <= num; i++) {
      levels[`level${i}`] = selectedValues[`level${i}`];
    }
    return levels;
  }, [selectedValues, num]);
  const getOptionsForLevel = (level) => {
    if (level === 1) return getChoices(choices);
    let parentChoices = choices;
    for (let i = 1; i < level; i++) {
      parentChoices =
        parentChoices.find((c) => c.label === levelsData[`level${i}`])
          ?.choices || [];
    }
    return getChoices(parentChoices);
  };
  const handleLevelChange = (level, value) => {
    const updatedValues = { ...selectedValues };
    for (let i = level; i <= num; i++) {
      delete updatedValues[`level${i}`];
    }
    if (value) {
      updatedValues[`level${level}`] = value;
    }
    handleChange(updatedValues);
  };
  const count = Object.keys(field_name).length;
  console.log("field_name------", count);
  return (
    <div className={`${count > 7 ? "overflow-x-auto" : ""} `}>
      {Array.from({ length: num }, (_, i) => i + 1).map((level) => {
        const options = getOptionsForLevel(level);
        if (!options.length) return null;
        return (
          <div
            key={`level${level}`}
            className="flex items-center mb-2 w-full"
            style={{ marginLeft: getLevelFieldsMargin(level) + "px" }}
          >
            {level > 1 && <CornerDownRight color="gray" size={18} />}
            <SelectData
              label={field_name[`level${level}`]}
              options={options}
              onChange={(value) => handleLevelChange(level, value)}
              value={levelsData[`level${level}`]}
              customCls={`${level > 3 ? "ml-2" : "ml-0"}`}
              level={level}
              fromApprovalPage={level === 1 ? fromApprovalPage : false}
              error={level === 1 ? error[id] : error[`level${level}`]}
            />
          </div>
        );
      })}
    </div>
  );
}
export default DependencyDropdown;
function SelectData({
  options = [],
  value = { label: "Select", value: "" },
  defaultValue,
  onChange,
  label,
  customCls = "w-full",
  level,
  fromApprovalPage,
  error,
}) {
  const isRequired =
    (fromApprovalPage && level === 1) || (!fromApprovalPage && level !== 1);
  return (
    <div className="w-[50%] mt-2">
      <SelectInput
        options={options}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="select"
        required={isRequired}
        label={label}
        error={error}
      />
      {error ? <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error}</div> : null}
    </div>
  );
}