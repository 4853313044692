import { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


function QuillInput({
  label,
  labelCls = "",
  inputCls = "",
  error,
  value = "",
  modules = [],
  name,
  onChange,
  ...inputProps
}) {
  const { required } = inputProps;
  const quillRef = useRef();

  return (
    <div className="w-full">
      {label && (
        <div className={`font-mulisemibold text-sm text-skin-base  ${required ? "required-input" : ""} ${labelCls}`}>
          {label}
        </div>
      )}
      <ReactQuill
        theme="snow"
        name={name}
        ref={quillRef}
        className={`rounded-2xl mt-1 ${inputCls}`}
        value={value}
        onChange={(value) => {
          const payload = {
            target: {
              name,
              value,
            },
          };

          onChange(payload);
        }}
        {...inputProps}
      />
      {error && <div className="mt-1 ml-1 text-xs font-mulilight text-skin-textred">{error}</div>}
    </div>
  );
}

export default QuillInput;
