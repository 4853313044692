import { useEffect, useRef } from "react";

function useDropDown({ open, setOpen, dontCloseOnReactSelect }) {
  const elementRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      const clickedElementId = e.target.getAttribute("id") || "";

      const isReactSelect = clickedElementId.includes("react-select");

      if (dontCloseOnReactSelect && isReactSelect) {
        return;
      }

      if (elementRef.current && !elementRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };

    setTimeout(() => {
      document.body.addEventListener("click", closeDropdown);
    }, 100);

    return () => document.body.removeEventListener("click", closeDropdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { elementRef };
}

export default useDropDown;
