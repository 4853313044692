import CustomSelect from "./CustomSelect";

// function SelectInput({
//   label,
//   labelCls = "",
//   inputCls = "",
//   error,
//   options = [],
//   value,
//   ...inputProps
// }) {
//   const { required, name, onChange } = inputProps;

//   return (
//     <div>
//       <div>
//         {label ? (
//           <div
//             className={`my-1 font-mulisemibold text-base text-skin-base ${required ? "required-input" : ""}  ${labelCls}`}
//           >
//             {label}
//           </div>
//         ) : null}

//         <CustomSelect
//           options={options}
//           value={value}
//           error={error}
//           onChange={(value) => {
//             const payload = {
//               target: {
//                 name,
//                 value,
//               },
//             };
//             onChange(payload);
//           }}
//         />
//       </div>

//       {error ? <div className="text-red-600 pt-2 text-sm">{error}</div> : null}
//     </div>
//   );
// }

// export default SelectInput;

import { useEffect, useState } from "react";
import { default as Selected } from "react-select";

const SelectInput = ({
  label = "Select",
  name = "field",
  className = "text-skin-base text-sm border-skin-line rounded-md h-11 border w-full",
  placeholder = "Select",
  value = "",
  children,
  required,
  options = [],
  error,
  onChange,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    if (options) {
      setSelectedOption(options.find((c) => c.value === value) || "");
    }
  }, [options, value]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 44,
      minHeight: 44,
      opacity: state.getValue()?.find((obj) => obj.disabled)?.disabled ? "60%" : "100%",
      border: state.isFocused ? "2px solid #DADCE0" : "",
      // This line disable the blue border
      fontFamily: "Muli light",
      fontSize: "text-skin-base",
      boxShadow: state.isFocused ? "0" : "",
      "&:hover": {
        border: state.isFocused ? "2px solid #DADCE0" : "",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      padding: "3px",
    }),
    option: (base, state) => {
      return {
        ...base,
        ":hover": {
          background: state.isDisabled ? "#fff" : "#d6f9ff",
          cursor: state.isDisabled ? "no-drop" : "pointer",
          color: state.isDisabled ? "#999" : "#182444",
        },
        background: state?.isSelected ? "#d6f9ff" : "#fff",
        color: state.isDisabled ? "#aaa" : state?.isSelected ? "#005f73" : "#555",
        cursor: "pointer",
        borderRadius: "3px",
        fontFamily: "Muli light",
        fontSize: "text-skin-base",
      };
    },
  };
  return (
    // ${required ? "required-input" : ""}
    <div className={`relative`}>
      <span className={`${required ? "required-input" : ""} absolute z-[1] bottom-[35px] left-2 bg-white pl-1 pr-1 text-skin-base font-mulisemibold text-sm`}>
        {label}
      </span>
      <Selected
        required={required}
        className="text-skin-base text-base border-skin-line rounded-md h-11"
        name={name}
        placeholder={placeholder}
        value={selectedOption}
        options={options}
        styles={customStyles}
        {...rest}
        onChange={(value) => {
          const payload = {
            target: {
              name,
              value: value?.value,
            },
          };
          onChange(payload);
        }}
      />
    </div>
  );
};

export default SelectInput;

