import { Search } from "lucide-react";

function SearchInput({ onSearch, customCls = "", searchValue }) {
  return (
    <div className="w-full relative flex rounded-md items-center justify-end">
      <Search className="cursor-pointer rounded-r-md right-0 h-full w-[35px] px-[10px] absolute bg-muted" size={20} color="gray" />
      <input
        className={`w-[260px] py-1.5 text-base h-[40px] font-mulilight rounded-md focus:border-skin-line focus:border-2 border-skin-line outline-none ${customCls}`}
        type="text"
        placeholder="Search"
        onChange={onSearch}
        value={searchValue || ""}
      />
    </div>
  );
}

export default SearchInput;
