import { useEffect, useState } from "react";
import {
  getAgoFromDate,
  handleFileUpload,
} from "../../common/helpers/commonHelpers";
import { Edit, UserCircle2 } from "lucide-react";
import FileUpload from "./FileUpload";
import PreviewAttachment from "../../common/components/PreviewAttachment";
import CommentForm from "./CommentForm";
import useApiData from "../../common/hooks/useApiData";
import { usePopup } from "../../common/contexts/PopupContext";
import { useAuth } from "../../common/contexts/AuthContext";

function DisplayComment({
  commentData = {},
  updateComment,
  isUpdatingComment,
  setCommentId,
  commentId,
}) {
  const [openAttachmentPreview, setOpenAttachmentPreview] = useState(false);
  //   const [edit, setEdit] = useState(false);
  const { user } = useAuth();
  const { id: userId } = user;
  const {
    comment,
    username,
    email,
    ticketAttachments,
    created_at,
    comment_id,
    state,
    id,
  } = commentData;

  const { display } = getAgoFromDate(created_at);

  const edit = commentId === comment_id;

  return (
    <>
      {" "}
      <div className="flex space-x-2 mb-8 w-full">
        <div>
          <UserCircle2 color="#005f73" fontFamily="Muli light" size={24}/>
          <div className="w-0.5 bg-gray-400 h-[88%] mx-auto"></div>
        </div>
        <div className="w-full">
          <div className="flex justify-between items-center">
            <div>
              <span className="font-mulibold text-skin-base mr-1.5">
                {username || email || "Unknown"}
              </span>
              <span className="text-gray-500 font-mulilight">
                added a comment {display || "now"}
              </span>
            </div>

            {edit || id !== userId || state !== 2 ? null : (
              <Edit
                size={15}
                className="cursor-pointer text-primary"
                onClick={() => setCommentId(comment_id)}
              />
            )}
          </div>

          {edit ? (
            <EditForm
              comment={comment}
              attachments={ticketAttachments}
              onCancel={() => setCommentId("")}
              updateComment={updateComment}
              isUpdatingComment={isUpdatingComment}
              comment_id={comment_id}
            />
          ) : (
            <div className="bg-white shadow-mainshadow text-skin-base font-mulilight p-2 px-4 rounded-md mt-2 w-full">
              <div className="mb-4 text-sm">
                <pre
                  style={{
                    fontFamily: "inherit",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {comment}
                </pre>
              </div>

              <FileUpload
                files={ticketAttachments || []}
                handleFileUpload={() => {}}
                onRemove={(index) => {}}
                onView={(link) => setOpenAttachmentPreview({ link })}
                onDownload={() => {}}
                disabled={true}
                // label="Attachments"
                id="commentAttachments"
                hideNoAttachments={true}
              />

              <PreviewAttachment
                openModal={openAttachmentPreview}
                setOpenModal={setOpenAttachmentPreview}
                link={openAttachmentPreview}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DisplayComment;

function udpateState(setState, update) {
  setState((prev) => ({ ...prev, ...update }));
}

function getDefaultForm() {
  return { comment: "", attachments: [], removeAttachments: [] };
}

function EditForm({
  comment,
  attachments,
  onCancel,
  updateComment,
  isUpdatingComment,
  comment_id,
}) {
  const [form, setForm] = useState({
    comment,
    attachments,
    removeAttachments: [],
  });
  const [error, setError] = useState(getDefaultForm());
  const [formLoading, setFormLoading] = useState(false);

  return (
    <div className="mb-8">
      <CommentForm
        form={form}
        error={error}
        onCancel={onCancel}
        onChangeTextArea={(e) => {
          const { name, value } = e.target;

          udpateState(setForm, { [name]: value });

          setError(getDefaultForm());
        }}
        handleFileUpload={(e) => {
          handleFileUpload(e, setFormLoading, (files) => {
            udpateState(setForm, {
              attachments: [...form.attachments, ...files],
            });

            setError(getDefaultForm());
          });
        }}
        onRemove={(index, file) => {
          const { attachments = [], removeAttachments = [] } = form;

          const remainingFiles = attachments.filter((a, i) => i !== index);

          const removed = [...removeAttachments];

          if (file.id) {
            removed.push(file.id);
          }

          udpateState(setForm, {
            attachments: remainingFiles,
            removeAttachments: removed,
          });
          setError(getDefaultForm());
        }}
        onSubmit={() => {
          if (!form.comment) {
            setError({ comment: "Please write a comment" });
            return;
          }

          const attachments = form.attachments.filter((a) => !a.id);

          updateComment({ ...form, attachments });
        }}
        loading={isUpdatingComment}
        submitLabel="Update"
      />
    </div>
  );
}
