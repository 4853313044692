function Textarea({
  label,
  labelCls = "",
  inputCls = "",
  error,
  value = "",
  ...inputProps
}) {
  const { required } = inputProps;

  return (
    <div className="w-full">
      {label && (
        <div className={`my-1 ${required ? "required-input" : ""} ${labelCls}`}>
          {label}
        </div>
      )}

      <textarea
        className={`border focus:border-none outline-none text-sm font-mulilight text-skin-base focus:ring-1 focus:ring-primary ${error ? "border-red-400" : "border-skin-line"
          } rounded-md w-full px-2 py-2 ${inputCls}`}
        value={value}
        {...inputProps}
      />

      {error && <div className="text-red-600 pt-2 text-sm">{error}</div>}
    </div>
  );
}

export default Textarea;
