import TicketCustomFields from "./TicketCustomFields";
import TicketAssignees from "./TicketAssignees";
import RelatedTickets from "./RelatedTickets";
import ChildTickets from "./ChildTickets";
import EditTopic from "./EditTopic";
import TicketComment from "./TicketComment";
import TicketContent from "./TicketContent";
import TicketDetails from "./TicketDetails";
import ShowAttachments from "./ShowAttachments";
import Loading from "../../common/components/Loading";
import { useAuth } from "../../common/contexts/AuthContext";
import { useEffect, useState } from "react";
import TicketTeamAssignees from "./TicketTeamAssignees";
import { useApp } from "../../common/contexts/AppContext";
import TicketCcs from "./TicketCcs";

function ViewTicket({
  ticket = {},
  updateTicket,
  updating,
  // customFields,
  // updateCustomFields,
  // isUpdatingCustomFields,
  customFieldsProps,
  addComment,
  isAddingComment,
  initialLoading,
  updateComment,
  isUpdatingComment,
  setCommentId,
  commentId,
  isGettingTicket,
}) {
  const { user } = useAuth();
  const { portal } = useApp();

  const {
    ticket_id,
    ticketContent,
    ticketAttachments,
    ticketCustomFields,
    assignedMembers,
    parentTicket,
    ticketComments,
    childTicket,
    created_by,
  } = ticket;

  const isITSM = portal?.theme !== "CUSTOMER_PORTAL";

  if (initialLoading) {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="font-mulilight text-gray-500 text-lg mb-4">
          Getting Ticket Details, please wait
        </div>
        <Loading />
      </div>
    );
  }
  return (
    <div className="md:flex grid justify-between my-4 pl-3 w-full">
      <div className="md:w-[54%] sm:w-full">
        <EditTopic
          topic={ticket?.topic}
          isUpdating={updating === "topic"}
          updateTicket={(topic) => {
            updateTicket({ topic, content: ticketContent }, "topic");
          }}
        />

        <div className="mt-2 ml-[7px] mb-4 text-base font-mulilight text-skin-base">
          #{ticket_id} created by {created_by}
        </div>

        <TicketContent
          ticketContent={ticketContent}
          isUpdating={updating === "content"}
          updateTicket={(content) => {
            updateTicket({ topic: ticket?.topic, content }, "content");
          }}
        />

        <ShowAttachments attachments={ticketAttachments} />

        <TicketComment
          ticketComments={ticketComments}
          addComment={addComment}
          isAddingComment={isAddingComment}
          updateComment={updateComment}
          isUpdatingComment={isUpdatingComment}
          setCommentId={setCommentId}
          commentId={commentId}
        />
      </div>

      <div className="md:w-[38%] text-gray-700 text-sm">
        <TicketDetails
          ticket={ticket}
          reOpenTicket={() => {
            updateTicket({ status: "open" }, "status");
          }}
          isUpdating={updating === "status" || isGettingTicket}
        />

        <TicketCustomFields
        //   ticketCustomFields={ticketCustomFields}
        //   customFields={customFieldsProps.customFields}
        //   updateCustomFields={customFieldsProps.updateCustomFields}
        //   isUpdatingCustomFields={customFieldsProps.isUpdatingCustomFields}
        //   edit={customFieldsProps.edit}
        //   setEdit={customFieldsProps.setEdit}
        {...customFieldsProps}
        />
        <TicketCcs ticketId={ticket_id} />

        {isITSM ? (
          <>
            {/* <TicketAssignees assignees={assignedMembers} /> */}

            <TicketTeamAssignees ticketId={ticket_id} />

            <RelatedTickets relatedTickets={parentTicket} />

            <ChildTickets childTicket={childTicket} />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default ViewTicket;
