import Select, { components } from "react-select";
import { default as Selected } from "react-select";
function CustomSelect({
  options = [],
  value,
  onChange,
  error,
  inputCls,
  ...props
}) {

  const style = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? "#D6F9FF" : !error ? "#005f73" : "red",
      color: "#005f73",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused ? "#D6F9FF" : !error ? "#005f73" : "red",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    }),
    // Style for menu items
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#D6F9FF" : "transparent",
      color: state.isFocused ? "#005f73" : "#333",
      "&:hover": {
        backgroundColor: "#D6F9FF",
      },
    
    }),
  };


  return (
    <Select
      options={options}
      value={value ? options.find((o) => o.value === value) : ""}
      onChange={(val = {}) => onChange(val?.value)}
      isSearchable
      isClearable
      styles={style}
      {...props}
      // className={}
      components={{
        Menu: (props) => <components.Menu {...props} className="menu" />,
      }}
    />
  );
}

export default CustomSelect;
