import Loading from "./Loading";

function PrimaryButton({
  children,
  onClick,
  btnProps = {},
  btnCls = "",
  loading,
  type,
  marginTop = "",
}) {
  return (
    <button
      className={`button_animi w-full bg-primary font-mulisemibold text-sm rounded-md flex justify-center items-center ${btnCls} ${marginTop}`}
      onClick={onClick}
      {...btnProps}
      disabled={btnProps?.disabled || loading}
      type={type || "button"}
    >
      {loading ? <Loading /> : null}
      {children}
    </button>
  );
}

export default PrimaryButton;
