import React, { useState, useEffect } from "react";
import Table from "../../common/components/Table";
import Sort from "./Sort";
import { priorities } from "../../common/helpers/commonHelpers";
import ShowColumns from "./ShowColumns";
import ToolTip from "../../common/components/ToolTip";
import Loading from "../../common/components/Loading";
import SearchInput from "../../common/components/SearchInput";
import Button from "../../common/components/Button";
import { useAuth } from "../../common/contexts/AuthContext";
import { useApp } from "../../common/contexts/AppContext";
import { StatusFilter, statusOptions } from "./TicketFilters";
import { checkAuth } from "../../common/helpers/commonHelpers";
import useCustomNavigate from "../../common/hooks/useCustomNavigate";
// import { useLocation } from "react-router-dom";
import { useLocation } from "react-router-dom";
function getDefaultTableColumns({
  toggleShowColumns,
  openTicketDetails,
  onSort,
  theme,
}) {
  let columns = [
    {
      name: (
        <Sort
          label="Issue No"
          onHide={() => toggleShowColumns("issueNo")}
          onSort={onSort}
          name="id"
        />
      ),
      renderComp: (data) => (
        <button
          className="text-primary font-semibold border-primary"
          onClick={() => openTicketDetails(data?.ticket_id)}
        >
          {data?.ticket_id}
        </button>
      ),
      value: "issueNo",
    },
    {
      name: "Topic",
      renderComp: (data) => data?.topic,
      value: "topic",
    },
    {
      name: "Assignee",
      renderComp: (data) => (
        <ShowAssignee assignedMembers={data?.assignedMembers || []} />
      ),
      value: "assignee",
    },
    {
      name: (
        <Sort
          label="Priority"
          onHide={() => toggleShowColumns("priority")}
          onSort={onSort}
          name="priority"
        />
      ),
      renderComp: (data) => {
        const priorityData = priorities.find((p) => p.value === data?.priority);

        return priorityData?.label;
      },
      value: "priority",
    },
    {
      name: (
        <Sort
          label="Status"
          onHide={() => toggleShowColumns("status")}
          onSort={onSort}
          name="status"
        />
      ),
      renderComp: (data) => {
        const selected = statusOptions?.find((s) => s.value === data?.status);

        return selected?.label;
      },
      value: "status",
    },
  ];

  if (theme === "CUSTOMER_PORTAL") {
    columns = columns.filter((c) => c.value !== "assignee");
  }

  return columns;
}

function getColumns({
  showColumns,
  additionalFields,
  toggleShowColumns,
  openTicketDetails,
  onSort,
  theme,
}) {
  const columns = [
    ...getDefaultTableColumns({
      toggleShowColumns,
      openTicketDetails,
      onSort,
      theme,
    }),
  ];

  if (additionalFields?.length) {
    additionalFields.forEach((field) => {
      const { id, field_name } = field;

      columns.push({
        name: (
          <Sort
            label={field_name}
            field={field}
            onHide={() => toggleShowColumns(id)}
            onSort={onSort}
            name={id}
          />
        ),
        renderComp: (data) => renderCustomFields(data, id),
        value: id,
      });
    });
  }

  return columns.filter((c) => showColumns.includes(c.value));
}

function getDefaultColumns(theme) {
  const defaultColumns = [
    {
      label: "Issue No",
      value: "issueNo",
    },
    {
      label: "Topic",
      value: "topic",
    },
    {
      label: "Assignee",
      value: "assignee",
    },
    {
      label: "Priority",
      value: "priority",
    },
    {
      label: "Status",
      value: "status",
    },
  ];

  let columns = defaultColumns;
  if (theme === "CUSTOMER_PORTAL") {
    columns = columns.filter((c) => c.value !== "assignee");
  }

  return columns;
}

function getAllColumns(defaultColumns, customFields = []) {
  const customFieldsColumns = [];

  customFields.forEach((c) => {
    if (c.field_type !== "dependency_dropdown") {
      customFieldsColumns.push({
        label: c.field_name,
        value: c.id,
      });
    }
  });

  return [...defaultColumns, ...customFieldsColumns];
}

function toggleShowColumns(value, setShowColumns, showColumns) {
  setShowColumns((prev) => {
    if (prev.includes(value)) {
      if (showColumns?.length === 1) {
        // if only one columns is presented, don't remove it
        return prev;
      }

      // if column is present, remove it
      return prev.filter((c) => c !== value);
    } else {
      // if column is not present, add it
      return [...prev, value];
    }
  });
}

function ListTicketsTable({
  openTicketDetails,
  tickets,
  additionalFields,
  communityFieldsLoading,
  onSearch,
  onSort,
  onClear,
  showTable,
  loading,
  data,
  searchValue,
  filters,
  onFilter,
}) {
  const { user } = useAuth();
  const { portal } = useApp();

  const [showColumns, setShowColumns] = useState(
    getDefaultColumns(portal?.theme).map((c) => c.value)
  );

  function updateShowColumns(value) {
    toggleShowColumns(value, setShowColumns, showColumns);
  }

  const columns = getColumns({
    showColumns,
    setShowColumns,
    additionalFields,
    toggleShowColumns: updateShowColumns,
    openTicketDetails,
    onSort,
    theme: portal?.theme,
  });
  const accessToken = checkAuth();
  const navigate = useCustomNavigate();

  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  const { status } = filters;

  return (
    <div className="mx-auto">
      <div className="md:hidden flex justify-between gap-2 items-center mt-24 w-full">
        <button
          className={`border w-[50%] text-sm font-mulisemibold rounded-md py-2 px-2 hover:opacity-90 
          ${currentRoute === "/tickets" ? "bg-primary text-white" : "bg-secondarylight border-primary text-primary"
            }`}
          onClick={() => navigate("/tickets")}>Tickets</button>
        {accessToken ? (
          <button
            className={`border  w-[50%] text-sm font-mulisemibold rounded-md py-2 px-2  hover:opacity-90
             ${currentRoute === "/create-ticket" || currentRoute === "/" ? "bg-primary text-white" : "bg-secondarylight border-primary text-primary"
              }`}
            onClick={() => navigate("/create-ticket")}
          >
            Create a ticket
          </button>
        ) : null}
      </div>
      <div className="md:flex justify-between items-center md:mt-24 mt-6">
        <div className="flex items-center space-x-2">
          <ShowColumns
            columns={getAllColumns(
              getDefaultColumns(portal?.theme),
              additionalFields
            )}
            showColumns={showColumns}
            setShowColumns={setShowColumns}
            toggleShowColumns={updateShowColumns}
            communityFieldsLoading={communityFieldsLoading}
          />
          <div className="sm:hidden">
            <SearchInput onSearch={onSearch} searchValue={searchValue} />
          </div>
          <div className="hidden sm:flex">
            <StatusFilter selectedStatus={status || ""} sortTicket={onFilter} />
          </div>
          {/* <Button
            className="text-sm px-4 py-2 rounded-md bg-primary text-white"
            onClick={() => {
              onClear();
              setShowColumns(
                getDefaultColumns(portal?.theme).map((c) => c.value)
                );
              }}
              >
              Clear All
            </Button> */}
        </div>
        <div className="hidden sm:flex">
          <SearchInput onSearch={onSearch} searchValue={searchValue} />
        </div>
        <div className="sm:hidden">
          <StatusFilter selectedStatus={status || ""} sortTicket={onFilter} />
        </div>
        {/* <SearchInput onSearch={onSearch} searchValue={searchValue} /> */}
      </div>

      {loading ? (
        <div className="w-full flex justify-center mt-6">
          <Loading />
        </div>
      ) : (
        <>
          {tickets?.length && data ? (
            <Table data={tickets} columns={columns} />
          ) : (
            <div className="font-mulilight text-gray-600 text-base text-center mt-20">
              {loading ? "" : "Tickets not found"}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ListTicketsTable;

function ShowAssignee({ assignedMembers }) {
  if (!assignedMembers?.length) {
    return <div className="text-gray-500">-</div>;
  }

  return (
    <div className="grid grid-cols-2 gap-3 w-fit">
      {assignedMembers.map((a, i) => {
        const { username, email } = a;
        const name = username || email;
        return (
          <ToolTip tip={name} key={i}>
            <div className="cursor-pointer w-6 h-6 rounded-full bg-gray-200 flex justify-center items-center">
              {name[0]}
            </div>
          </ToolTip>
        );
      })}
    </div>
  );
}

function renderCustomFields(data, id) {
  const { customfield_values } =
    data?.customFields?.find((c) => c.customfield_id === id) || {};

  if (typeof customfield_values === "string") {
    return customfield_values;
  } else {
    return "-";
  }
}
